import { DraftType } from "./draft";
import { Ewaybill, EwaybillResponse } from "./ewaybill";
import { ShipmentNotification } from "@app/user/documents/shipment-notification/shipment-notification";
import { EinvoicepmtDto as Einvoicepmt } from "./einvoicepmt";
import { Einvoice } from "./einvoice";
import { Order } from "./order";
import { Transit } from "./transit";
import { TransitDocument } from "@app/user/documents/transit/transit";
import { Edocument } from "@app/user/documents/edocument/edocument";
import { Status } from "@helper/abstraction/status";
import { Eact } from "./eact";
import { ContentDto } from "./content";
import { StorageCreateDto } from "./storages";
import { PartnerDto, RoseuBundleDto } from "./roseu";

export interface DocumentsParams {
	page: number;
	size: number;
	documentDateEnd: Date;
	documentDateStart: Date;
	documentTypeId: DocumentType;
	documentState: DocumentState; // Состояние документа: Черновик, Входящие, Исходящие
	processingStatusList?: Status[];
	documentNumber?: string;
	edocumentNumber?: string;
	partner?: Status;
	storage?: Status;
	partnerId?: string;
	storageId?: string;
	documentType?: DocType;
	formType?: DocType;
	documentDirection?: DocType;
	useDocumentDate?: string;
	edocumentType?: EdocumentType;
	fileName?: string;
}

export interface DocumentBody {
	documentNumber?: string;
	edocumentNumber?: string;
	documentDateStart: Date;
	documentDateEnd: Date;
	partnerId?: string;
	storageId?: string;
	processingStatusList?: Status[];
	documentType?: DocType | string;
	formType?: DocType | string;
	documentDirection?: DocType | string;
	useDocumentDate?: boolean;
	typeCode?: string;
	fileName?: string;
	documentState?: DocumentState;
}

export interface EditDocumentsParams extends DocumentParams {
	draftId: string;
}

export interface CreateDocumentParams extends DocumentParams {
	isTest: boolean;
}

export interface DocumentParams {
	draftType: DraftType;
	formType?: { id: string, name: string };
	version?: string;
}

export interface Document {
	id: string;
	status?: string;
	messageType: MessageType;
	formType?: number;
	processingStatus?: {
		id: string;
		name: string;
	};
	documentNumber?: string;
	deliveryError?: string;
	edocumentNumber?: string;
	documentDate?: string;
	signatureDate?: string;
	signatureTime?: string;
	documentNameCode?: string;
	receiver?: string;
	sender?: string;
	senderId: number;
	senderDto?: PartnerDto;
	receiverDto?: PartnerDto;
	receiverId: number;
	read?: boolean;
	documentType?: string;
	shipmentStorage?: StorageCreateDto;
	deliveryStorage?: StorageCreateDto;
	dateTime?: string;
	documentState?: string;

	// TODO test model
	carrierId?: number;
	carrier?: {
		id: number;
		gln: string;
		name: string;
		unp: string;
	};
	consignee?: {
		id: number;
		gln: string;
		name: string;
		unp: string;
	};
	consignor?: {
		id: number;
		gln: string;
		name: string;
		unp: string;
	};
	shipFrom?: {
		id: number;
		gln: string;
		address: string;
	};
	shipTo?: {
		id: number;
		gln: string;
		address: string;
	};
	version?: string | number;
}

export interface DocumentsDto extends ContentDto {
	content: Document[];
}

export interface Pageable {
	offset: number;
	pageNumber: number;
	pageSize: number;
	paged: boolean;
	sort: Sort;
	unpaged: boolean;
}

export interface DocumentProperty {
	type?: string;
	key: string;
	name: string;
	children?: DocumentProperty[];
}

export interface Sort {
	empty: boolean;
	sorted: boolean;
	unsorted: boolean;
}

export const processingStatusValue: string[] = [
	"ACCEPTED", "ACCEPTED_WITH_CHANGES", "CANCELED_BY_SUPPLIER", "CANCELED_BY_BUYER", "ACCEPTED_CANCELED_BY_BUYER",
	"AUTOMATICALLY_CANCELLED", "CANCELED", "TRANSFERRED", "RECEIVED",
	"CREATED", "CHANGE_REQUIRED", "CREATED_CONFIRMED", "NOT_CONFIRMED_CANCEL",
	"CHANGE_REQUIRED_NOT_CONFIRMED", "CREATED_CANCEL_SENT", "CREATED_CONFIRMED_CANCEL_SENT", "REPLACED", "TRANSFERRED_WITH_ERROR"];

export type DocumentKind = Ewaybill | EwaybillResponse | ShipmentNotification | Order | Einvoice | Einvoicepmt | Transit | TransitDocument | Edocument | Eact;

export type DocumentState = "DRAFT" | "INCOMING" | "OUTGOING" | "ALL" | "WAITING_FOR_PROCESSING" | "AT_PARTNER" | "COMPLETED" | "LOADING";

export type DocumentType = "EWAYBILL" | "ORDERS" | "DESADV" | "ORDRSP" | "EINVOICE" | "EINVOICEPMT" | "TRANSIT" | "EDOCUMENT" | "BLRDOC" | "ROAMING" | "EACTDIF" | "THIRDPARTYACCESS" | "ECMR" | "ROSEU";

export type MessageType = "BLRWBL" | "BLRDLN" | "ORDERS" | "DESADV" | "ORDRSP" | "BLRDNR" | "BLRWBR" | "BLRINV" | "BLRPMT" | "BLRSPT" | "TRANSIT" | "BLRDOC" | "BLRDOCNEW" | "BLRADF" | "ECMR" | "ROSEU" | "REUMSG" | "REUINF" | "UPD";

export interface DescriptionDocumentType {
	id: DocumentType;
	name: string;
}

export type ListTypeAction = "SUCCESS" | "RESET" | "CANCEL";

export interface DescriptionDocumentTypeStates {
	action?: ListTypeAction;
	sourceList?: DescriptionDocumentType[];
	targetList?: DescriptionDocumentType[];
}

export type KindByMessageType<T> = T extends "BLRWBL" ? Ewaybill : T extends "BLRDLN" ? Ewaybill : T extends "BLRDNR" ? Ewaybill : T extends "BLRWBR" ? Ewaybill : T extends "BLRINV" ? Einvoice : T extends "ORDERS" ? Order : T extends "DESADV" ? ShipmentNotification : T extends "BLRPMT" ? Einvoicepmt : T extends "BLRADF" ? Eact : T extends "ROSEU" ? RoseuBundleDto : never;

export interface DocumentFromAnotherParams {
	destinationDocumentType?: MessageType;
	id?: number;
	sourceDocumentType?: MessageType;
	testIndicator?: boolean;
}

export type DocumentsTypesDto = DocumentType[];

export interface ExportDocument {
	documentIdList: number[];
	msgType: MessageType;
}

export interface NewInboxDocumentsCountDto {
	newOrdMessagesNumber: number;
	newDesadvMessagesNumber: number;
	newEwaybillMessagesNumber: number;
	newTransitMessagesNumber: number;
}

export type ImportReport = ImportReportItem[];

export interface ImportReportItem {
	fileName: string;
	successful: boolean;
	deliveryNoteNumber: string;
	errorDescription: string;
}

export interface MassReport {
	failedCount: number;
	successCount: number;
	errorReports: MassItemReport[];
}

export interface MultipleSendingDto {
	draftIds: number[];
	responseDraftIds: number[];
}

export interface MassItemReport {
	msgType: DraftType;
	documentId: number;
	documentNumber: string;
	errorMessage: string;
	errorCode: string;
	exception: {
		cause: {
			localizedMessage: string;
			message: string;
		};
	};
	type?: string;
	document?: Document;
}

export interface MassSignReport {
	failedCount?: number;
	successCount?: number;
	errorReports?: MassItemReport[] | [];
}

export interface DocType {
	id: string;
	name: string;
}

export interface ImportResultFromDataMarkFileDto {
	draftId: number;
	draftType: string;
	dataMarkCount: number;
	deliveryNoteNumber: string;
	failedGtinList: string[],
	failedStringCount: number;
	lineItemCount: number;
	isDuplicatesRemoved: boolean;
}

export interface DocumentSignedDto {
	id?: number;
	signature?: string;
	signatureBase64?: string;
	xmlBody?: string;
}

export interface StatusDto {
	draft?: boolean;
	deliveryStatus: number;
	id: number;
	number: string;
	processingStatus: number | Status;
}

export interface EdocumentType {
	code: string;
	name: string;
}
