import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";

import { ConfigurationService } from "@core/configuration.service";
import { DocumentController } from "./document-controller";
import { BlrdocDto } from "@app/user/documents/blrdoc/blrdoc";
import { DocumentForSignDto } from "./ewaybill";

export class Blrdoc extends DocumentController<BlrdocDto> {
	public get apiUrl(): string {
		const api = this.config.api.blrdoc;
		return `${api}`;
	}

	public checkSign = {
		get$: (id: string): Observable<boolean> => {
			const url = `${this.apiUrl}/${id}/checkSign`;
			return this.http.get<boolean>(url, { withCredentials: true });
		}
	};

	public saveResponse = {
		post$: (draftId: number): Observable<number> => {
			const url = `${this.apiUrl}/${draftId}/response`;
			return this.http.post<number>(url, {}, { withCredentials: true });
		}
	};

	public downloadBLRDOCFile = {
		get$: (id: number): Observable<HttpResponse<Blob>> => {
			const url = `${this.apiUrl}/${id}/file`;
			return this.http.get<Blob>(url, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public saveBLRDOCFile = {
		put$: (draftId: number, file: File): Observable<number> => {
			const url = `${this.apiUrl}/${draftId}/file`;
			const formData: FormData = new FormData();
			formData.append("file", file, file.name);
			return this.http.put<number>(url, formData, { withCredentials: true });
		}
	};

	public deleteFile = {
		delete$: (draftId: number): Observable<number> => {
			const url = `${this.apiUrl}/${draftId}/file`;
			return this.http.delete<number>(url, { withCredentials: true });
		}
	};

	public getValidBlrdoc = {
		get$: (id: number): Observable<DocumentForSignDto> => {
			const url = `${this.apiUrl}/${id}/signed`;
			let params = new HttpParams();
			params = params.set("base64", "true");
			return this.http.get<DocumentForSignDto>(url, { withCredentials: true, params })
		}
	}

	public sendBlrdocDraft = {
		post$: (draftId: number): Observable<void> => {
			const url = `${this.apiUrl}/${draftId}/send`;
			return this.http.post<void>(url, {}, { withCredentials: true });
		}
	};

	public downloadArchive = {
		post$: (documentIds: number[]): Observable<HttpResponse<Blob>> => {
			let url = `${this.apiUrl}/downloadArchive`;
			return this.http.post<Blob>(url, documentIds, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public cancelBLRDOC = {
		post$: (id: number, base64?: boolean): Observable<DocumentForSignDto> => {
			const url = `${this.apiUrl}/${id}/blrapn/cancel`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.post<DocumentForSignDto>(url, {}, { withCredentials: true, params });
		}
	};

	public saveSignedAndSend = {
		post$: (signDocumentDto: { id: number; xmlBody: string; signature: string | number[], signatureBase64?: string }): Observable<any> => {
			const url = `${this.apiUrl}/blrapn/saveSignedAndSend`;
			return this.http.post<any>(url, signDocumentDto, { withCredentials: true });
		}
	};

	constructor(
		public config: ConfigurationService,
		public http: HttpClient
	) {
		super(http);
	}

	public get$(id: number): Observable<BlrdocDto> {
		const url = `${this.apiUrl}/${id}`;
		return this.http.get<BlrdocDto>(url, { withCredentials: true });
	}
}
