<div (appClickOutside)="onClickOutside()" >
	<main (click)="switchExpandState($event)" [class.error]="showError" [class.deleteIcon]="disabled" [class.new-style-main]="newStyle" [class.error-border]="showErrorBorder"
		[class.active-main]="newStyle && expand" [class.disabled-main]="newStyle && disabled">
		<div class="container">
			<div class="element" [class.deleteIcon]="!!value && !disabled">
				<input type="text" [value]="value && valueTransformFn ? valueTransformFn(value) : value" readonly="readonly" [disabled]="disabled"
				[class.isValue]="!!value && !newStyle" [placeholder]="placeholder">
				<app-icon *ngIf="!!value && !disabled && !hideClear && !newStyle" (click)="deleteItem($event)">close</app-icon>
			</div>
		</div>
		<app-icon *ngIf="!newStyle" (click)="switchExpandState($event)">{{!expand? "unfold_more": "unfold_less"}}</app-icon>
		<app-icon *ngIf="newStyle" [class.new-style-icon]="newStyle">{{ !expand ? 'arrow_drop_down' : 'arrow_drop_up' }}</app-icon>
	</main>
	<app-list *ngIf="expand" [data]="list" (appInput)="onInput($event)" (appSelect)="addItem($event)"
		(appScrolled)="onScrolled()" [showSearch]="showSearch" [newStyle]="newStyle" [class.new-style-list]="newStyle"></app-list>
</div>
