import { Observable } from "rxjs";
import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CurrenciesParams, CurrenciesDto } from "@helper/abstraction/currency";
import { GeneralGLNsParams, GeneralGLNsDto } from "@helper/abstraction/generalGLN";
import {
	ExtraFieldsParams,
	ExtraFieldsDto,
	ProductExtraFieldsParams,
	ProductExtraFieldDto
} from "@helper/abstraction/extra-fields";
import { UnitOfMeasuresParams, UnitOfMeasureDto } from "@helper/abstraction/unit-of-measures";
import { CountriesParams, CountryDto } from "@helper/abstraction/countries";
import { CertificatesDto, CertificateParams } from "@helper/abstraction/certificate";
import { RegionDto, RegionsParams } from "@helper/abstraction/regions";
import { StreetDto, StreetsParams } from "@helper/abstraction/streets";
import { InspectionDto, MnsDto, MnsParams } from "@helper/abstraction/mns";
import { DocumentType } from "@helper/abstraction/documents";
import { ReportTypeDto } from "@helper/abstraction/reports";
import { NsiDocTypeDto, NsiMsgGroupType, NsiEdocTypeDto, NsiEdocType } from "@helper/abstraction/nsi-doc-type";
import { GtkParams } from "@helper/abstraction/gtk";
import { SelectBoxSelfFetchState } from "@shared/select-box-self-fetch/select-box-self-fetch.component";
import { IdWithName, NsiTnvedDto } from "@app/user/marks/marks-store/marks-types";
import { NsiEcmrFieldDto } from "@helper/abstraction/extra-documents";
import { OkrbDto, OkrbParams, OkrbType } from "@helper/abstraction/okrb";

export class Nsi {
	public currencies = {
		list: {
			get$: (cp: CurrenciesParams): Observable<CurrenciesDto> => {
				const url = `${this.config.api.root}/nsi/currencies/list`;
				let params = new HttpParams();
				params = params.set("page", cp.page.toString());
				params = params.set("size", cp.size.toString());

				if (cp.searchText) {
					params = params.set("currencyName", cp.searchText);
				}

				return this.http.get<CurrenciesDto>(url, { params, withCredentials: true });
			},
		}
	};

	public tnved = {
		list: {
			get$: (p?: SelectBoxSelfFetchState): Observable<NsiTnvedDto[]> => {
				const url = `${this.config.api.root}/nsi/tnved/list`;
				let params = new HttpParams();
				if (p) {
					params = params.set("page", p?.page?.toString());
					params = params.set("size", p?.size?.toString());
				}
				// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
				if (p?.search) {
					params = params.set("tnvedFilter", p.search.toString());
				}

				return this.http.get<NsiTnvedDto[]>(url, { params, withCredentials: true });
			},
		}
	};

	public oked = {
		list: {
			get$: (cp: SelectBoxSelfFetchState): Observable<IdWithName[]> => {
				const url = `${this.config.api.root}/nsi/oked/list`;
				let params = new HttpParams();
				params = params.set("page", cp.page.toString());
				params = params.set("size", cp.size.toString());

				return this.http.get<IdWithName[]>(url, { params, withCredentials: true });
			},
		}
	};

	public generalGLN = {
		list: {
			get$: (gp: GeneralGLNsParams): Observable<GeneralGLNsDto> => {
				const url = `${this.config.api.root}/nsi/general-gln/list`;
				let params = new HttpParams();
				params = params.set("page", gp.page.toString());
				params = params.set("size", gp.size.toString());

				if (gp.searchText) {
					params = params.set("glnName", gp.searchText);
				}

				return this.http.get<GeneralGLNsDto>(url, { params, withCredentials: true });
			},
		}
	};

	public extraFields = {
		list: {
			get$: (exp: ExtraFieldsParams): Observable<ExtraFieldsDto> => {
				const url = `${this.config.api.root}/nsi/extra-fields/list`;
				let params = new HttpParams();
				params = params.set("page", exp.page.toString());
				params = params.set("size", exp.size.toString());

				if (exp.search) {
					params = params.set("extraFieldName", exp.search);
				}
				else if (exp.searchText) {
					params = params.set("extraFieldName", exp.searchText);
				}

				return this.http.get<ExtraFieldsDto>(url, { params, withCredentials: true });
			}
		}
	};

	public consignees = {
		extraFields: {
			list: {
				get$: (exp: ExtraFieldsParams): Observable<ExtraFieldsDto> => {
					const url = `${this.config.api.root}/nsi/consignees/extra-fields/list`;
					let params = new HttpParams();
					params = params.set("page", exp.page.toString());
					params = params.set("size", exp.size.toString());

					if (exp.search) {
						params = params.set("extraFieldName", exp.search);
					}
					else if (exp.searchText) {
						params = params.set("extraFieldName", exp.searchText);
					}


					return this.http.get<ExtraFieldsDto>(url, { params, withCredentials: true });
				}
			}
		}
	};

	public reportType = {
		list: {
			get$: (): Observable<ReportTypeDto> => {
				const url = `${this.config.api.root}/nsi/reportType/list`;
				return this.http.get<ReportTypeDto>(url, { withCredentials: true });
			}
		}
	};

	public edocumentType = {
		list: {
			get$: (): Observable<NsiEdocTypeDto | NsiEdocType[]> => {
				const url = `${this.config.api.root}/nsi/edocumentType/list`;
				return this.http.get<NsiEdocTypeDto | NsiEdocType[]>(url, { withCredentials: true });
			}
		}
	};

	public uom = {
		list: {
			get$: (uomp: UnitOfMeasuresParams, documentType: DocumentType): Observable<UnitOfMeasureDto> => {
				const url = `${this.config.api.root}/nsi/uom/list`;
				let params = new HttpParams();
				params = params.set("page", uomp.page.toString());
				params = params.set("size", uomp.size.toString());
				params = params.set("documentType", documentType);

				if (uomp.search) {
					params = params.set("uomName", uomp.search);
				}
				else if (uomp.searchText) {
					params = params.set("uomName", uomp.searchText);
				}
				return this.http.get<UnitOfMeasureDto>(url, { params, withCredentials: true });
			}
		}
	};

	public okrb = {
		list: {
			get$: (okrbp: OkrbParams, okrbType: OkrbType): Observable<OkrbDto> => {
				const url = `${this.config.api.root}/nsi/okrb/list`;
				let params = new HttpParams();
				params = params.set("page", okrbp.page.toString());
				params = params.set("size", okrbp.size.toString());
				params = params.set("okrbType", okrbType);

				if (okrbp.search) {
					params = params.set("okrbName", okrbp.search);
				} else if (okrbp.searchText) {
					params = params.set("okrbName", okrbp.searchText);
				}
				return this.http.get<OkrbDto>(url, { params, withCredentials: true });
			}
		}
	};

	public countries = {
		list: {
			get$: (cp: CountriesParams): Observable<CountryDto> => {
				const url = `${this.config.api.root}/nsi/countries/list`;
				let params = new HttpParams();
				params = params.set("page", cp.page.toString());
				params = params.set("size", cp.size.toString());
				if (cp.search) {
					params = params.set("countryName", cp.search);
				}
				else if (cp.searchText) {
					params = params.set("countryName", cp.searchText);
				}
				return this.http.get<CountryDto>(url, { params, withCredentials: true });
			}
		}
	};

	public docType = {
		list: {
			get$: (dt: SelectBoxSelfFetchState): Observable<NsiDocTypeDto> => {
				const url = `${this.config.api.root}/nsi/docType/list`;
				let params = new HttpParams();
				params = params.set("page", dt.page.toString());
				params = params.set("size", dt.size.toString());
				if (dt.search) {
					params = params.set("typeName", dt.search.toString());
				}
				return this.http.get<NsiDocTypeDto>(url, { params, withCredentials: true });
			}
		}
	};

	public regions = {
		list: {
			get$: (rd: RegionsParams): Observable<RegionDto> => {
				const url = `${this.config.api.root}/nsi/regions/list`;
				let params = new HttpParams();
				params = params.set("page", rd.page.toString());
				params = params.set("size", rd.size.toString());

				if (rd.search) {
					params = params.set("regionName", rd.search);
				}
				else if (rd.searchText) {
					params = params.set("regionName", rd.searchText);
				}

				return this.http.get<RegionDto>(url, { params, withCredentials: true });
			}
		}
	};

	public streets = {
		types: {
			list: {
				get$: (sp: StreetsParams): Observable<StreetDto> => {
					const url = `${this.config.api.root}/nsi/streets/types/list`;
					let params = new HttpParams();
					params = params.set("page", sp.page.toString());
					params = params.set("size", sp.size.toString());

					if (sp.searchText) {
						params = params.set("typeName", sp.searchText);
					}

					return this.http.get<StreetDto>(url, { params, withCredentials: true });
				}
			}
		}
	};

	public mns = {
		list: {
			get$: (mp: MnsParams): Observable<MnsDto> => {
				const url = `${this.config.api.root}/nsi/mns/list`;
				let params = new HttpParams();
				params = params.set("page", mp.page.toString());
				params = params.set("size", mp.size.toString());

				if (mp.searchText) {
					params = params.set("mnsName", mp.searchText);
				}

				return this.http.get<MnsDto>(url, { params, withCredentials: true });
			}
		},
		filtered: {
			get$: (mp: MnsParams): Observable<MnsDto> => {
				const url = `${this.config.api.root}/nsi/mns/filtered`;
				let params = new HttpParams();
				params = params.set("page", mp.page.toString());
				params = params.set("size", mp.size.toString());
				if (mp.userType) {
					params = params.set("userType", mp.userType);
				}
				if (mp.searchText) {
					params = params.set("namePattern", mp.searchText);
				}
				return this.http.get<MnsDto>(url, { params, withCredentials: true });
			}
		}
	};

	public gtk = {
		filtered: {
			get$: (gtkParams: GtkParams): Observable<any> => {
				const url = `${this.config.api.root}/nsi/gtk/filtered`;
				let params = new HttpParams()
					.set("page", gtkParams.page.toString())
					.set("size", gtkParams.size.toString());

				if (gtkParams.userType) {
					params = params.set("userType", gtkParams.userType);
				}
				if (gtkParams.searchText) {
					params = params.set("namePattern", gtkParams.searchText);
				}

				return this.http.get<any>(url, { params, withCredentials: true });
			}
		}
	};

	public inspections = {
		filtered: {
			get$: (inspectionType: string = "mns", userType: string): Observable<InspectionDto[]> => {
				const url = `${this.config.api.root}/nsi/${inspectionType}/filtered`;
				let params = new HttpParams()
					.set("page", "1")
					.set("size", "50");

				if (userType) {
					params = params.set("userType", userType);
				}

				return this.http.get<InspectionDto[]>(url, { params, withCredentials: true });
			}
		}
	};

	public certificates = {
		types: {
			list: {
				get$: (dtp: CertificateParams): Observable<CertificatesDto> => {
					const url = `${this.config.api.root}/nsi/certificates/types/list`;
					let params = new HttpParams();
					params = params.set("page", dtp.page.toString());
					params = params.set("size", dtp.size.toString());

					if (dtp.searchText) {
						params = params.set("typeName", dtp.searchText);
					}

					return this.http.get<CertificatesDto>(url, { params, withCredentials: true });
				}
			}
		}
	};

	public msgGroupType = {
		list: {
			get$: (): Observable<NsiMsgGroupType[]> => {
				const url = `${this.config.api.root}/nsi/msg-group-type/list`;
				return this.http.get<NsiMsgGroupType[]>(url, { withCredentials: true });
			}
		}
	};

	public item = {
		extraFields: {
			list: {
				get$: (pefp: ProductExtraFieldsParams): Observable<ProductExtraFieldDto> => {
					const url = `${this.config.api.root}/nsi/items/extra-fields/list`;
					let params = new HttpParams();
					params = params.set("page", pefp.page.toString());
					params = params.set("size", pefp.size.toString());

					if (pefp.searchText) {
						params = params.set("extraFieldName", pefp.searchText);
					}

					return this.http.get<ProductExtraFieldDto>(url, { params, withCredentials: true });
				}
			}
		}
	};

	public delivery = {
		list: {
			get$: (exp: ExtraFieldsParams): Observable<NsiEcmrFieldDto> => {
				const url = `${this.config.api.root}/nsi/delivery/term/list`;
				let params = new HttpParams();
				params = params.set("page", exp.page.toString());
				params = params.set("size", exp.size.toString());

				if (exp.searchText) {
					params = params.set("namePattern", exp.searchText);
				}

				return this.http.get<NsiEcmrFieldDto>(url, { params, withCredentials: true });
			}
		}
	};

	public extraDocuments = {
		list: {
			get$: (exp: ExtraFieldsParams): Observable<NsiEcmrFieldDto> => {
				const url = `${this.config.api.root}/nsi/document/type/list`;
				let params = new HttpParams();
				params = params.set("page", exp.page.toString());
				params = params.set("size", exp.size.toString());

				if (exp.searchText) {
					params = params.set("namePattern", exp.searchText);
				}

				return this.http.get<NsiEcmrFieldDto>(url, { params, withCredentials: true });
			}
		}
	};

	public packageType = {
		list: {
			get$: (exp: ExtraFieldsParams): Observable<NsiEcmrFieldDto> => {
				const url = `${this.config.api.root}/nsi/package/type/list`;
				let params = new HttpParams();
				params = params.set("page", exp.page.toString());
				params = params.set("size", exp.size.toString());

				if (exp.searchText) {
					params = params.set("namePattern", exp.searchText);
				}

				return this.http.get<NsiEcmrFieldDto>(url, { params, withCredentials: true });
			}
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}
