import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, map, take, takeUntil, tap } from "rxjs/operators";

import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";
import { OverlayService } from "@core/overlay.service";
import { GetUserFormCacheDto, UserFormCacheCode, UserFormCacheDto } from "@helper/abstraction/user";
import { ValidatorsUtil } from "@helper/validators-util";

export interface SelfFetchOption<T> {
	getInitialState: () => GetUserFormCacheDto;
	nextChunk: (state: GetUserFormCacheDto) => GetUserFormCacheDto;
	getData$?: (state: GetUserFormCacheDto) => Observable<UserFormCacheDto>;
	mapData?: (data: UserFormCacheDto) => UserFormCacheDto[];
}

@Component({
	selector: "app-form-cache-grid",
	templateUrl: "./form-cache-grid.component.html",
	styleUrls: ["./form-cache-grid.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormCacheGridComponent implements OnInit {
	@Input("option") public selfFetchOption: SelfFetchOption<any>;
	@Input() public code?: UserFormCacheCode;
	@Input() public label?: string;
	@Output() public appFormValue: EventEmitter<UserFormCacheDto> = new EventEmitter<UserFormCacheDto>();
	public data: any[] = [];
	public input$$: Subject<GetUserFormCacheDto> = new Subject<GetUserFormCacheDto>();
	public form?: FormGroup;
	public notfound = false;
	public error?: string;
	public isLoading = false;

	private initialState: GetUserFormCacheDto = { page: 1, size: 50 };
	private state$$: BehaviorSubject<GetUserFormCacheDto>;
	private defaultOption: SelfFetchOption<any> = {
		getInitialState: (): GetUserFormCacheDto => ({ ...this.initialState }),
		nextChunk: (state): GetUserFormCacheDto => ({ ...state, page: state.page + 1 })
	};
	private unsubscribe$$: Subject<void> = new Subject<void>();
	private stateTracker$$?: Subject<void> = new Subject<void>();

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
		public fB: FormBuilder,
		private overlayService: OverlayService,
		private userErrorsService: UserErrorsService,
		private userBackendService: UserBackendService,
	) {
		this.isLoading = true;
		this.selfFetchOption = { ...this.defaultOption };
		this.state$$ = new BehaviorSubject<GetUserFormCacheDto>(this.selfFetchOption.getInitialState());
		this.input$$.pipe(
			debounceTime(250),
			takeUntil(this.unsubscribe$$)
		).subscribe(text => this.onInput(text));
	}

	public ngOnInit(): void {
		this.form = this.initForm();
		this.form.get("content")?.valueChanges
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(() => this.search());
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.selfFetchOption && simpleChanges.selfFetchOption.currentValue) {
			this.selfFetchOption = { ...this.defaultOption, ...this.selfFetchOption };
		}
	}

	public clearForm(isSwitchMode = false): void {
		if (isSwitchMode) {
			this.setDefaultFormValue(isSwitchMode);
			return;
		}
		this.setDefaultFormValue();
		this.input$$.next();
	}

	public search(): void {
		this.isLoading = true;
		this.error = undefined;
		ValidatorsUtil.triggerValidation(this.form!);
		if (this.form?.invalid) {
			this.userErrorsService.displayErrors(this.form, undefined, undefined, true);
			return;
		}
		const formValue = this.form?.getRawValue();
		const newState = { ...this.selfFetchOption.getInitialState(), code: this.code, ...formValue };
		this.input$$.next(newState);
	}

	public checkForm(): boolean {
		const formValues = this.form?.getRawValue();
		delete formValues.gtinMode;
		const dataValues = Object.values(formValues).filter(fl => !!fl);
		return !!dataValues.length;
	}

	public ngAfterViewInit(): void {
		this.setData(this.data);
		this.unfold();
		this.changeDetectorRef.markForCheck();
	}

	public select(item: UserFormCacheDto): void {
		this.collapse();
		this.appFormValue.emit(item);
		this.changeDetectorRef.markForCheck();
	}

	public onInput(search?: GetUserFormCacheDto): void {
		this.resetData();
		this.cleanStateTracker();
		const newState = { ...this.selfFetchOption.getInitialState(), ...search };
		this.state$$.next(newState);
		this.stateTracker$$ = this.trackState$$();
	}

	public onScrolled(): void {
		this.state$$.next(this.selfFetchOption.nextChunk(this.state$$.value));
	}

	public collapse(): void {
		this.cleanStateTracker();
		this.resetData();
	}

	public unfold(): void {
		this.state$$.next(this.selfFetchOption.getInitialState());
		this.stateTracker$$ = this.trackState$$();
	}

	public ngOnDestroy(): void {
		this.cleanStateTracker();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public removeItem(item: UserFormCacheDto): void {
		this.isLoading = true;
		this.userBackendService.user.deleteUserFormCache.post$(item)
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(() => {
					this.search();
				},
				error => {
					this.isLoading = false;
					this.overlayService.showNotification$(error?.error?.error, "error", undefined, undefined, true);
				}
		);
	}

	public trackState$$(): Subject<void> {
		const unsubscribe$$ = new Subject<void>();
		this.state$$.pipe(
			takeUntil(this.unsubscribe$$),
			takeUntil(unsubscribe$$)
		).subscribe(state => {
			if (!this.selfFetchOption.getData$)
				throw Error("No getData$ using for enrich date");

			this.selfFetchOption.getData$({ ...state, code: this.code}).pipe(
				take(1),
				map(data => this.selfFetchOption.mapData ? this.selfFetchOption.mapData(data) : data as any),
				takeUntil(this.unsubscribe$$),
				takeUntil(unsubscribe$$)
			).subscribe(data => {
				if (Array.isArray(data) && !data.length) {
					unsubscribe$$.next();
					unsubscribe$$.complete();
					this.isLoading = false;
				}
				this.appendData(data);
				this.isLoading = false;
				this.notfound = this.checkForm() && !!!this.data.length;
				this.changeDetectorRef.detectChanges();
			},
			(error: HttpErrorResponse) => {
				this.isLoading = false;
				this.error = error.error.error;
				this.changeDetectorRef.detectChanges();
			});
		});
		return unsubscribe$$;
	}

	private cleanStateTracker(): void {
		if (this.stateTracker$$) {
			this.stateTracker$$.next();
			this.stateTracker$$.complete();
			delete this.stateTracker$$;
		}
	}

	private resetData(): void {
		this.data = [];
	}

	private appendData(newData: UserFormCacheDto[]): void {
		this.data = [...this.data, ...newData];
	}

	private setData(newData: UserFormCacheDto[]): void {
		this.data = newData;
	}

	private setDefaultFormValue(isMode = false): void {
		this.form?.patchValue({
			content: null,
		}, { emitEvent: false });
	}

	private initForm(): FormGroup {
		const form = this.fB.group({
			content: [null],
		});
		return form;
	}
}
