import { Component, EventEmitter, Input, Output } from "@angular/core";

import { UserFormCacheDto } from "@helper/abstraction/user";

@Component({
  selector: "app-form-cache-list",
  templateUrl: "./form-cache-list.component.html",
  styleUrls: ["./form-cache-list.component.scss"]
})
export class FormCacheListComponent {
	@Input() public data: UserFormCacheDto[] = [];
	@Input() public notFound = false;
	@Input() public isLoading = false;
	@Input() public error?: string;
	@Output() public appSelect: EventEmitter<UserFormCacheDto> = new EventEmitter<UserFormCacheDto>();
	@Output() public removeItem: EventEmitter<UserFormCacheDto> = new EventEmitter<UserFormCacheDto>();

  public onRowSelect(event: any): void {
    this.appSelect.emit(event.data as UserFormCacheDto);
  }

  public remove(item: UserFormCacheDto): void {
    this.removeItem.emit(item);
  }
}
