import { Injectable } from "@angular/core";
import { PlatformLocation } from "@angular/common";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store, createSelector, select } from "@ngrx/store";
import { UserState } from "@app/user/user.reducer";
import { UserProfile } from "@helper/abstraction/user";
import { notNull } from "@helper/operators";
import { MnsState } from "@app/mns/mns-store/mns.reducer";

interface ApiModel {
	root: string;
	transit: string;
	desadv: string;
	ewaybill: string;
	einvoice: string;
	eact: string;
	ecmr: string;
	order: string;
	roseu: string;
	user: string;
	einvoicepmt: string;
	mns: string;
	gov: string;
	draftResponse: string;
	party: string;
	roaming: string;
	crypto: string;
	edocument: string;
	blrdoc: string;
	datamark: string;
	epass: string;
	help: string;
}

@Injectable()
export class ConfigurationService {
	private readonly baseHref: string;
	private version: string = "/v1";
	private signingPort = 9999;
	private eds: string | undefined;
	private edsProxyUrl?: string = "localhost";
	private unsubscribe$$ = new Subject<void>();

	constructor(
		platformLocation: PlatformLocation,
		private store: Store
	) {
		this.baseHref = platformLocation.getBaseHrefFromDOM();

		const user = (appState: any): UserState => appState.user;
		const userTypeState = createSelector(user, (state: UserState): UserProfile | undefined => state?.userProfile);
		this.store.pipe(select(userTypeState), notNull(), takeUntil(this.unsubscribe$$)).subscribe(userProfile => {
			this.eds = userProfile?.port || "";
			this.edsProxyUrl = userProfile.edsProxyUrl || "localhost";
		});
		const mns = (appState: any): MnsState => appState.mns;
		const mnsTypeState = createSelector(mns, (state: MnsState): UserProfile | undefined => state?.userProfile);
		this.store.pipe(select(mnsTypeState), notNull(), takeUntil(this.unsubscribe$$)).subscribe(userProfile => {
			this.eds = userProfile?.port || "";
			this.edsProxyUrl = userProfile.edsProxyUrl || "localhost";
		});
	}

	public get api(): ApiModel {
		return {
			root: `${this.baseHref}api${this.version}`,
			desadv: `${this.baseHref}api${this.version}/DESADV`,
			crypto: `${this.baseHref}SCCrypto`,
			ewaybill: `${this.baseHref}api${this.version}/EWAYBILL`,
			einvoice: `${this.baseHref}api${this.version}/EINVOICE`,
			eact: `${this.baseHref}api${this.version}/EACTDIF`,
			ecmr: `${this.baseHref}api${this.version}/ECMR`,
			einvoicepmt: `${this.baseHref}api${this.version}/EINVOICEPMT`,
			edocument: `${this.baseHref}api${this.version}/EDOCUMENT`,
			blrdoc: `${this.baseHref}api${this.version}/BLRDOC`,
			transit: `${this.baseHref}api${this.version}/TRANSIT`,
			order: `${this.baseHref}api${this.version}/ORDERS`,
			roseu: `${this.baseHref}api${this.version}/ROSEU`,
			user: `${this.baseHref}api${this.version}/user`,
			party: `${this.baseHref}api${this.version}/party`,
			roaming: `${this.baseHref}api${this.version}/ROAMING/`,
			mns: `${this.baseHref}api${this.version}/mns`,
			gov: `${this.baseHref}api${this.version}/gov`,
			datamark: `${this.baseHref}api${this.version}/datamark`,
			epass: `${this.baseHref}api${this.version}/epass`,
			draftResponse: `${this.baseHref}api${this.version}/draft/response`,
			help: `${this.baseHref}api${this.version}/section/help`,
		};
	}

	public get signingServiceNew(): string {
		return `http://${this.edsProxyUrl}:${this.eds || this.signingPort}`;
	}

	public set newPort(port: number) {
		this.signingPort = port;
	}

	public get port(): number {
		return this.eds ? +this.eds : this.signingPort;
	}

	public resetEdsPort(): void {
		this.eds = undefined;
	}

	public resetEdsUrl(url: string): void {
		this.edsProxyUrl = url;
	}

	public handleVersionPath(value: string, path?: string): string {
		return value;
		/** TODO - version api */
		// return typeof path === "string" ? value.replace(this.version, path) : value;
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
