import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { FilterTradeItemDto, TradeItemDto } from "@app/user/marks/marks-store/marks-types";
import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { SelectBoxSelfFetchState } from "./search-items-grid/search-items-grid.component";
import { DocumentType } from "@helper/abstraction/documents";

@Component({
	selector: "app-search-items-popup",
	templateUrl: "./search-items-popup.component.html",
	styleUrls: ["./search-items-popup.component.scss"]
})
export class SearchItemsPopupComponent implements OnInit {
	public gtinMode = false;
	public documentType?: DocumentType;
	public itemsSelectBoxOption = {
		getData$: (selectBoxState: SelectBoxSelfFetchState): Observable<TradeItemDto[]> => {
			const params: FilterTradeItemDto = {
				...selectBoxState,
			};
			return this.userBackendService.epass.searchItem.post$(params);
		},
		mapData: (data: TradeItemDto[]): TradeItemDto[] => data,
	};

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		private userBackendService: UserBackendService,
	) { }

	public ngOnInit(): void {
		this.setData();
	}

	public setData(): void {
		this.config.width = "820px";
		this.config.header = "Поиск товара";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
		this.config.height = "630px";
	}

	public close(): void {
		this.ref.close();
	}

	public addItem(item: TradeItemDto): void {
		this.ref.close(item);
	}
}
