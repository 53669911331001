import { ScrollToFirstInvalidDirective } from "./directives/scroll-to-first-invalid/scrollToFirstInvalidDirective.directive";
import { TreeMakerComponent } from "./tree-maker/tree-maker.component";
import { ElementWithTooltipComponent } from "./element-with-tooltip/element-with-tooltip.component";
import { SccryptoProfilePopupComponent } from "@shared/shared-popups/sccrypto-profile-popup/sccrypto-profile-popup.component";
import { TooltipDirective } from "./tooltip/tooltip.directive";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ButtonComponent } from "./button/button.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { ClickOutsideDirective } from "./directives/click-outside/click-outside.directive";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { DateboxComponent } from "./datebox/datebox.component";
import { DecadePickerComponent } from "./decade-picker/decade-picker.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { FormValueChangesDirective } from "./directives/form-value-change/form-value-change.directive";
import { GridDrawerComponent } from "./grid-drawer/grid-drawer.component";
import { IconComponent } from "./icon/icon.component";
import { ListComponent } from "./list/list.component";
import { LogoComponent } from "./logo/logo.component";
import { MonthPickerComponent } from "./month-picker/month-picker.component";
import { MonthRangePicker } from "./month-range-picker/month-range-picker.component";
import { MultiSelectBoxComponent } from "./multiselect-box/multiselect-box.component";
import { MultiSelectComponent } from "./multiselect/multiselect.component";
import { NumberboxComponent } from "./numberbox/numberbox.component";
import { NotificationComponent } from "./notification/notification.component";
import { OverlayComponent } from "./overlay/overlay.component";
import { PasswordboxComponent } from "./passwordbox/passwordbox.component";
import { RadioButtonListComponent } from "./radio-button-list/radio-button-list.component";
import { RangeDatePickerComponent } from "./range-date-picker/range-date-picker.component";
import { RangeboxComponent } from "./rangebox/rangebox.component";
import { SelectDateRangeComponent } from "./select-date-range/select-date-range.component";
import { SelectDateComponent } from "./select-date/select-date.component";
import { SelectboxComponent } from "./selectbox/selectbox.component";
import { SkinComponent } from "./skin/skin.component";
import { TabItemComponent } from "./tab-item/tab-item.component";
import { TabComponent } from "./tab/tab.component";
import { TextboxComponent } from "./textbox/textbox.component";
import { YearPickerComponent } from "./year-picker/year-picker.component";
import { DropListComponent } from "./drop-list/drop-list.component";
import { GridComponent } from "@shared/grid/grid.component";
import { HintDirective } from "@shared/hint/hint.directive";
import { HelpHintDynamicComponent } from "@shared/hint/hint-component/hint.component";
import { ConfirmationPopupComponent } from "./shared-popups/confirmation-popup/confirmation-popup.component";
import { SmartJoinPipe } from "@shared/smart-join/smart-join.pipe";
import { TimePickerComponent } from "./time-picker/time-picker.component";
import { PendingComponent } from "./pending/pending.component";
import { NativeElementToFormControlProviderDirective } from "./directives/native-element-to-form-control-provider/native-element-to-form-control-provider.directive";
import { FieldNameToFormControlProviderDirective } from "./directives/field-name-to-formControl-provider/field-name-to-formControl-provider.directive";
import { SelectBoxSelfFetchComponent } from "./select-box-self-fetch/select-box-self-fetch.component";
import { OptionalCustomFieldComponent } from "./optional-custom-field/optional-custom-field.component";
import { TextareaComponent } from "./textarea/textarea.component";
import { DragAndDropDirective } from "./directives/drag-and-drop/drag-and-drop.directive";
import { DropErrorComponent } from "./drop-error/drop-error.component";
import { MultiselectBoxSelfFetchComponent } from "@shared/multiselect-box-self-fetch/multiselect-box-self-fetch.component";
import { InstallProxyPopupComponent } from "./proxy-popup/install-proxy-popup/install-proxy-popup.component";
import { ProxyConfirmPopupComponent } from "./proxy-popup/proxy-confirm-popup/proxy-confirm-popup.component";
import { PasswordErrorPopupComponent } from "./proxy-popup/password-error-popup/password-error-popup.component";
import { ShipperReceiverMultiselectComponent } from "./shipper-receiver-multiselect/shipper-receiver-multiselect.component";
import { NotificationSequenceComponent } from "@shared/notification-sequence/notification-sequence.component";
import { MultiSelectBoxWithCheckboxComponent } from "./multiselect-box-with-checkbox/multiselect-box-with-checkbox.component";
import { ListWithCheckboxComponent } from "./list-with-checkbox/list-with-checkbox.component";
import { DropdownDirective } from "./directives/app-dropdown/dropdown.directive";
import { GridTreeComponent } from "./tree-maker/grid-tree/grid-tree.component";
import { DataMarkPopupComponent } from "@shared/shared-popups/data-mark-popup/data-mark-popup.component";
import { PhoneTextboxComponent } from "./phone-textbox/phone-textbox.component";
import { SelectBoxComponent } from "./controls/select-box/select-box.component";
import { CertificationMobilePopupComponent } from "./shared-popups/certification-mobile-popup/certification-mobile-popup.component";
import { SignatureMobilePopupComponent } from "./shared-popups/signature-mobile-popup/signature-mobile-popup.component";
import { GoodsFilterComponent } from "./goods-components/goods-filter/goods-filter.component";
import { GoodsGridComponent } from "./goods-components/goods-grid/goods-grid.component";
import { GoodsReportPopup } from "./shared-popups/goods-report-popup.component.html/goods-report-popup.component.html.component";
import { SelectGoodsComponent } from "./controls/select-goods/select-goods.component";
import { TableInSelectComponent } from "./controls/select-goods/table-in-select/table-in-select.component";
import { FileImportComponent } from "./controls/file-import/file-import.component";
import { MarkCodesReportPopupComponent } from "./shared-popups/mark-codes-report-popup/mark-codes-report-popup.component";
import { AutomaticGridComponent } from "./automatic-grid/automatic-grid.component";
import { StoreService } from "./automatic-grid/store.service";
import { AutoSelectBoxComponent } from "./controls/auto-select-box/auto-select-box.component";
import { SelectStoreService } from "./controls/auto-select-box/select-store.service";
import { SccryptoDocumentsPopupComponent } from "./shared-popups/sccrypto-documents-popup/sccrypto-documents-popup.component";
import { PrintingPopupComponent } from "./shared-popups/printing-popup/printing-popup.component";
import { ScCryptoRedefinePortPopupComponent } from "./shared-popups/sc-crypto-redefine-port-popup/sc-crypto-redefine-port-popup.component";
import { StoragePopupComponent } from "./storage-popup/storage-popup.component";
import { StorageGridComponent } from "./storage-popup/storage-grid/storage-grid.component";
import { MultilineListComponent } from "./storage-popup/multiline-list/multiline-list.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { SharedIconsComponent } from "./shared-icons/shared-icons-component";
import { UserDebetPopupComponent } from "./shared-popups/user-debet-popup/user-debet-popup.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { UserNotifyPopupComponent } from './shared-popups/user-notify-popup/user-notify-popup.component';
import { ProcessPasswordPopupComponent } from './shared-popups/process-password-popup/process-password-popup.component';
import { TooltipModule } from 'primeng/tooltip';
import { WindowResizeDirective } from "./directives/window-resize/window-resize.directive";
import { MenuIconComponent } from "./menu-icon/menu-icon.component";
import { TableModule } from "primeng/table";
import { EditCellGridComponent } from "./primeng-components/edit-cell-grid/edit-cell-grid.component";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { NumberComponent } from "./primeng-components/number/number.component";
import { EditGridComponent } from './primeng-components/edit-grid/edit-grid.component';
import { ConfirmDialogComponent } from "./shared-popups/confirm-dialog/confirm-dialog.component";
import { CheckboxModule } from 'primeng/checkbox';
import { PartyPopupComponent } from "./shared-popups/party-popup/party-popup.component";
import { PartyGridComponent } from "./shared-popups/party-popup/party-grid/party-grid.component";
import { PartyListComponent } from "./shared-popups/party-popup/party-list/party-list.component";
import { KeydownDirective } from "./directives/keydown/keydown.directive";
import { ProxyPopupComponent } from "./proxy-popup/proxy-popup/proxy-popup.component";
import { CryptoMobilePopupComponent } from "./shared-popups/crypto-mobile-popup/crypto-mobile-popup.component";
import { BindMobilePopupComponent } from "./shared-popups/bind-mobile-popup/bind-mobile-popup.component";
import { SkeletonModule } from 'primeng/skeleton';
import { RegistrationSimpleHelpComponent } from "@app/registration-simple/registration-simple-help/registration-simple-help.component";
import { DtsReceiptPopupComponent } from "./shared-popups/dts-receipt-popup/dts-receipt-popup.component";
import { NoRolePageComponent } from "./no-role-page/no-role-page.component";
import { SvgAggregateComponent } from "./svg-aggregate/svg-aggregate.component";
import { SccryptoVersionPopupComponent } from "./shared-popups/sccrypto-version-popup/sccrypto-version-popup.component";
import { SearchItemsPopupComponent } from "./shared-popups/search-items-popup/search-items-popup.component";
import { SearchItemsGridComponent } from "./shared-popups/search-items-popup/search-items-grid/search-items-grid.component";
import { SearchItemsListComponent } from "./shared-popups/search-items-popup/search-items-list/search-items-list.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { PlateComponent } from "./plate/plate.component";
import { FormCachePopupComponent } from "./shared-popups/form-cache-popup/form-cache-popup.component";
import { FormCacheListComponent } from "./shared-popups/form-cache-popup/form-cache-list/form-cache-list.component";
import { FormCacheGridComponent } from "./shared-popups/form-cache-popup/form-cache-grid/form-cache-grid.component";
import { ProgressBarModule } from 'primeng/progressbar';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        InputTextModule,
        ButtonModule,
        ToastModule,
        TooltipModule,
        InputNumberModule,
        DynamicDialogModule,
        CheckboxModule,
        SkeletonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        InputSwitchModule,
        ProgressBarModule,
    ],
    exports: [
        GoodsFilterComponent,
        GoodsGridComponent,
        DropErrorComponent,
        DragAndDropDirective,
        ClickOutsideDirective,
        DropListComponent,
        ButtonComponent,
        CheckboxComponent,
        DateboxComponent,
        FileUploadComponent,
        FormValueChangesDirective,
        IconComponent,
        LogoComponent,
        MultiSelectBoxComponent,
        MultiSelectComponent,
        NumberboxComponent,
        OverlayComponent,
        PasswordboxComponent,
        RadioButtonListComponent,
        RangeboxComponent,
        SelectboxComponent,
        SkinComponent,
        TabComponent,
        TabItemComponent,
        TextboxComponent,
        PhoneTextboxComponent,
        TextareaComponent,
        GridComponent,
        GridDrawerComponent,
        GridTreeComponent,
        DatePickerComponent,
        TimePickerComponent,
        HintDirective,
        SmartJoinPipe,
        PendingComponent,
        FieldNameToFormControlProviderDirective,
        ScrollToFirstInvalidDirective,
        NativeElementToFormControlProviderDirective,
        SelectBoxSelfFetchComponent,
        OptionalCustomFieldComponent,
        MultiselectBoxSelfFetchComponent,
        ShipperReceiverMultiselectComponent,
        TooltipComponent,
        TooltipDirective,
        ElementWithTooltipComponent,
        ListWithCheckboxComponent,
        MultiSelectBoxWithCheckboxComponent,
        DropdownDirective,
        TreeMakerComponent,
        SelectBoxComponent,
        SelectGoodsComponent,
        TableInSelectComponent,
        FileImportComponent,
        AutomaticGridComponent,
        AutoSelectBoxComponent,
        GoodsReportPopup,
        PaginationComponent,
        SharedIconsComponent,
        SccryptoProfilePopupComponent,
        SccryptoDocumentsPopupComponent,
        MarkCodesReportPopupComponent,
        PrintingPopupComponent,
        DataMarkPopupComponent,
        ScCryptoRedefinePortPopupComponent,
        PasswordErrorPopupComponent,
        ProxyConfirmPopupComponent,
        ProxyPopupComponent,
        InstallProxyPopupComponent,
        CertificationMobilePopupComponent,
        BindMobilePopupComponent,
        SignatureMobilePopupComponent,
        CryptoMobilePopupComponent,
        TooltipModule,
        WindowResizeDirective,
        MenuIconComponent,
        TableModule,
        EditCellGridComponent,
        NumberComponent,
        EditGridComponent,
        KeydownDirective,
        RegistrationSimpleHelpComponent,
        DtsReceiptPopupComponent,
        NoRolePageComponent,
        SvgAggregateComponent,
        SccryptoVersionPopupComponent,
        SearchItemsPopupComponent,
        SearchItemsGridComponent,
        SearchItemsListComponent,
        PlateComponent,
        FormCachePopupComponent,
        FormCacheListComponent,
        FormCacheGridComponent,
    ],
    declarations: [	
        GoodsFilterComponent,
        GoodsGridComponent,
        DropErrorComponent,
        DragAndDropDirective,
        DropListComponent,
        ButtonComponent,
        CheckboxComponent,
        ClickOutsideDirective,
        DateboxComponent,
        DatePickerComponent,
        TimePickerComponent,
        DecadePickerComponent,
        FileUploadComponent,
        FormValueChangesDirective,
        GridComponent,
        GridDrawerComponent,
        GridTreeComponent,
        IconComponent,
        ListComponent,
        LogoComponent,
        MonthPickerComponent,
        MonthRangePicker,
        MultiSelectBoxComponent,
        MultiSelectComponent,
        NotificationComponent,
        NotificationSequenceComponent,
        NumberboxComponent,
        OverlayComponent,
        SelectBoxSelfFetchComponent,
        PasswordboxComponent,
        RadioButtonListComponent,
        RangeboxComponent,
        RangeDatePickerComponent,
        SelectboxComponent,
        SelectDateComponent,
        SelectDateRangeComponent,
        SkinComponent,
        TabComponent,
        TabItemComponent,
        TextboxComponent,
        PhoneTextboxComponent,
        TextareaComponent,
        YearPickerComponent,
        HintDirective,
        HelpHintDynamicComponent,
        SmartJoinPipe,
        PendingComponent,
        ScrollToFirstInvalidDirective,
        NativeElementToFormControlProviderDirective,
        FieldNameToFormControlProviderDirective,
        SelectBoxSelfFetchComponent,
        OptionalCustomFieldComponent,
        MultiselectBoxSelfFetchComponent,
        ShipperReceiverMultiselectComponent,
        TooltipComponent,
        TooltipDirective,
        ElementWithTooltipComponent,
        ListWithCheckboxComponent,
        MultiSelectBoxWithCheckboxComponent,
        DropdownDirective,
        TreeMakerComponent,
        SelectBoxComponent,
        GoodsReportPopup,
        SelectGoodsComponent,
        TableInSelectComponent,
        FileImportComponent,
        AutomaticGridComponent,
        AutoSelectBoxComponent,
        PaginationComponent,
        SharedIconsComponent,
        SccryptoProfilePopupComponent,
        SccryptoDocumentsPopupComponent,
        MarkCodesReportPopupComponent,
        ConfirmationPopupComponent,
        PrintingPopupComponent,
        ScCryptoRedefinePortPopupComponent,
        PasswordErrorPopupComponent,
        ProxyConfirmPopupComponent,
        ProxyPopupComponent,
        InstallProxyPopupComponent,
        CertificationMobilePopupComponent,
        BindMobilePopupComponent,
        DataMarkPopupComponent,
        SignatureMobilePopupComponent,
        CryptoMobilePopupComponent,
        StoragePopupComponent,
        StorageGridComponent,
        MultilineListComponent,
        UserDebetPopupComponent,
        UserNotifyPopupComponent,
        ProcessPasswordPopupComponent,
        WindowResizeDirective,
        MenuIconComponent,
        EditCellGridComponent,
        NumberComponent,
        EditGridComponent,
        ConfirmDialogComponent,
        PartyPopupComponent,
        PartyGridComponent,
        PartyListComponent,
        KeydownDirective,
        RegistrationSimpleHelpComponent,
        DtsReceiptPopupComponent,
        NoRolePageComponent,
        SvgAggregateComponent,
        SccryptoVersionPopupComponent,
        SearchItemsPopupComponent,
        SearchItemsGridComponent,
        SearchItemsListComponent,
        PlateComponent,
        FormCachePopupComponent,
        FormCacheListComponent,
        FormCacheGridComponent,
    ],
    providers: [
        StoreService,
        SelectStoreService,
        DialogService,
    ]
})
export class SharedModule { }
