<section>
  <form *ngIf="form" [formGroup]="form">
    <div class="filed-column">
        <div class="field-block">
          <app-textbox
            formControlName="content"
            placeholder="Поиск"
            maxLength="512"
            name="{{ label }}">
          </app-textbox>
        </div>
    </div>
  </form>
  <app-form-cache-list
    [class.loading-state]="isLoading"
    [error]="error"
    [data]="data"
    [notFound]="notfound"
    [isLoading]="isLoading"
    (appSelect)="select($event)"
    (removeItem)="removeItem($event)">
  </app-form-cache-list>
</section>