import { Component, Input } from "@angular/core";

export type PlateType = "ERROR" | "WARNING" | "SUCCESSFULL" | "NORMAL";

@Component({
  selector: "app-plate",
  templateUrl: "./plate.component.html",
  styleUrls: ["./plate.component.scss"]
})
export class PlateComponent {
  @Input() public text?: string;
  @Input() public plateType: PlateType = "NORMAL";
  @Input() public isActive: boolean = false;

  public getPlateType(): string {
    return `plate-block ${this.plateType.toLocaleLowerCase()}-plate`;
  }
}
