import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { GetUserFormCacheDto, UserFormCacheCode, UserFormCacheDto } from "@helper/abstraction/user";

interface FormCacheModel {
  id: UserFormCacheCode;
  name: string;
}

const formCachePopupHeader: FormCacheModel[] = [
  { id: "EMPLOYEE", name: "Отпуск разрешил" },
  { id: "SHIP_TO", name: "Пункт разгрузки" },
  { id: "TRANSPORT", name: "Автомобиль" },
];

@Component({
  selector: "app-form-cache-popup",
  templateUrl: "./form-cache-popup.component.html",
  styleUrls: ["./form-cache-popup.component.scss"]
})
export class FormCachePopupComponent implements OnInit {
  public formCacheMode: UserFormCacheCode = "EMPLOYEE";
  public label?: string;

	public itemsSelectBoxOption = {
		getData$: (selectBoxState: GetUserFormCacheDto): Observable<UserFormCacheDto[]> => {
			const params: GetUserFormCacheDto = {
				...selectBoxState,
			};
			return this.userBackendService.user.userFormCache.post$(params);
		},
		mapData: (data: UserFormCacheDto[]): UserFormCacheDto[] => data,
	};

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		private userBackendService: UserBackendService,
	) { }

	public ngOnInit(): void {
		this.setData();
	}

	public setData(): void {
		this.config.width = "540px";
		this.config.height = "600px";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
		this.config.header = this.label;
	}

	public close(): void {
		this.ref.close();
	}

	public addItem(item: UserFormCacheDto): void {
		this.ref.close(item);
	}
}
