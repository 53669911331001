<section>
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="search()">
    <div class="filed-column">
      <div *ngIf="documentType === 'EWAYBILL'" class="field-block switch-block">
        <label>Ввести GTIN ограниченной циркуляции</label>
        <p-inputSwitch formControlName="gtinMode"></p-inputSwitch>
      </div>
      <div class="field-raw">
        <div class="field-block">
          <label [class.required]="gtinMode && documentType === 'EWAYBILL'">GTIN</label>
          <app-numberbox
            formControlName="gtin"
            placeholder="{{ gtinMode ? 'Введите GTIN (о.ц.)' : 'Поиск по GTIN' }}"
            maxLength="14"
            name="GTIN">
          </app-numberbox>
        </div>
        <div class="field-block">
          <label [class.required]="gtinMode && documentType === 'EWAYBILL'">Наименование</label>
          <app-textbox
            formControlName="name"
            placeholder="{{ gtinMode ? 'Введите наименование' : 'Поиск по наименованию (не менее 4 символов)' }}"
            maxLength="512"
            name="Наименование">
          </app-textbox>
        </div>
        <button [disabled]="!checkForm()" type="button" appSkin="simple-gray no-shadow bold" (click)="clearForm(gtinMode)">Сбросить</button>
        <button [disabled]="isLoading" type="submit" appSkin="simple-gray no-shadow bold">{{ gtinMode ? "Добавить" : "Поиск" }}</button>
      </div>
    </div>
  </form>
  <app-search-items-list [data]="data" [notFound]="notfound" (appSelect)="select($event)" [gtinMode]="gtinMode" [documentType]="documentType"></app-search-items-list>
</section>
