<ng-container *ngIf="data?.length || !data?.length && isLoading; else emptyTable">
  <div class="data-block">
    <ng-container *ngIf="isLoading">
      <p-progressBar mode="indeterminate" [style]="{'height': '5px'}"></p-progressBar>
    </ng-container>
    <p-table
      styleClass="p-datatable-striped"
      [value]="data"
      [scrollable]="true"
      scrollHeight="flex"
      selectionMode="single"
      (onRowSelect)="onRowSelect($event)">
      <ng-template pTemplate="body" let-item>
          <tr [pSelectableRow]="item">
            <td>
              <span class="column-list-item">
                <span class="list-item">{{ item.attribute1 }}</span>
                <span class="list-item atribute-2">{{ item.attribute2 }}</span>
              </span>
              <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" [icon]="'pi pi-trash'" (click)="remove(item)"></button>
            </td>
          </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
<ng-template #emptyTable>
  <div class="empty-table" [class.empty-loading]="isLoading">
    <span [class.error-response]="error">
      <ng-container *ngIf="!notFound && !error || notFound && !error">
        <ng-container>Данные отсутствуют. Введите значение в поле на форме редактирования</ng-container>
      </ng-container>
      <ng-container *ngIf="!notFound && error">
        <ng-container>{{ error }}</ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>
