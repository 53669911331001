<div (appClickOutside)="onClickOutside()" >
	<main (click)="switchExpandState($event)" [class.error]="showError" [class.deleteIcon]="disabled" [class.expand]="expand">
		<div class="container">
			<div class="element" [class.deleteIcon]="!!value && !disabled" [title]="fieldName === 'storages' ? '' : value ? valueTransformFn ? valueTransformFn(value) : value : ''">
				<input type="text" [value]="value && valueTransformFn ? valueTransformFn(value) : value" readonly="readonly" [disabled]="disabled"
				[class.isValue]="!!value" [placeholder]="placeholder">
				<app-icon *ngIf="!!value && !disabled" (click)="deleteItem($event)">close</app-icon>
				<span *ngIf="fieldName === 'storages' && value" class="tooltip-text">
					<span class="item-name">{{ value.storageName }}</span>
					<span class="item-address">{{ value.name }}</span>
					<span class="item-gln">{{ value.gln }}</span>
				</span>
			</div>
		</div>
		<app-icon *ngIf="!pending" (click)="switchExpandState($event)">{{!expand? "unfold_more": "unfold_less"}}</app-icon>
		<div *ngIf="pending" class="loading">
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
					style="margin: auto; background: rgba(0, 0, 0, 0); display: block;" width="45px" height="45px" viewBox="0 0 100 100"
					preserveAspectRatio="xMidYMid">
						<circle cx="50" cy="50" fill="none" stroke="rgb(0, 102, 209)" stroke-width="7" r="32" stroke-dasharray="150.79644737231007 52.26548245743669" transform="rotate(334.662 50 50)">
							<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.9900990099009901s" values="0 50 50;360 50 50" keyTimes="0;1">

							</animateTransform>
						</circle>
				</svg>
		</div>

	</main>
	<app-list [fieldName]="fieldName" [documentTypeId]="documentTypeId" *ngIf="expand" [data]="list" (appInput)="onInput($event)" (appSelect)="addItem($event)"
		(appScrolled)="onScroll()" [showSearch]="showSearch"></app-list>
</div>
