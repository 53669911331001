import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, filter, map, take, takeUntil, tap } from "rxjs/operators";

import { FilterTradeItemDto, TradeItemDto } from "@app/user/marks/marks-store/marks-types";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";
import { DocumentType } from "@helper/abstraction/documents";
import { ValidatorsUtil } from "@helper/validators-util";
import { OverlayService } from "@core/overlay.service";

export interface SelectBoxSelfFetchState {
	[key: string]: any;
	page: number;
	size: number;
}

export interface SelfFetchOption<T> {
	getInitialState: () => SelectBoxSelfFetchState;
	nextChunk: (state: SelectBoxSelfFetchState) => SelectBoxSelfFetchState;
	getData$?: (state: SelectBoxSelfFetchState) => Observable<T>;
	mapData?: (data: T) => T[];
}

@Component({
	selector: "app-search-items-grid",
	templateUrl: "./search-items-grid.component.html",
	styleUrls: ["./search-items-grid.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchItemsGridComponent implements OnInit {
	@Input("option") public selfFetchOption: SelfFetchOption<any>;
	@Input() public gtinMode = false;
	@Input() public documentType?: DocumentType;
	@Output() public appFormValue: EventEmitter<TradeItemDto> = new EventEmitter<TradeItemDto>();
	public data: TradeItemDto[] = [];
	public input$$: Subject<FilterTradeItemDto> = new Subject<FilterTradeItemDto>();
  	public form?: FormGroup;
	public notfound = false;
	public isLoading = false;
	private initialState: SelectBoxSelfFetchState = { page: 1, size: 50 };
	private state$$: BehaviorSubject<SelectBoxSelfFetchState>;
	private stateTracker$$?: Subject<void>;

	private defaultOption: SelfFetchOption<any> = {
		getInitialState: (): SelectBoxSelfFetchState => ({ ...this.initialState }),
		nextChunk: (state): SelectBoxSelfFetchState => ({ ...state, page: state.page + 1 })
	};
	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
		public fB: FormBuilder,
		private overlayService: OverlayService,
		private userErrorsService: UserErrorsService,
	) {
		this.selfFetchOption = { ...this.defaultOption };
		this.state$$ = new BehaviorSubject<SelectBoxSelfFetchState>(this.selfFetchOption.getInitialState());
		this.input$$.pipe(
			debounceTime(250),
			takeUntil(this.unsubscribe$$)
		).subscribe(text => this.onInput(text));
	}

	@HostListener("keydown", ["$event"]) public onSubmit(target: any): void {
		if (["Enter", "NumpadEnter"].includes(target.code) && !this.isLoading) {
			this.search();
			target.preventDefault();
		}
	}

	public ngOnInit(): void {
		this.form = this.initForm();
		if (this.gtinMode) {
			this.form?.get("gtinMode")?.patchValue(this.gtinMode);
			this.setGtinValidation();
		}
		
		this.form?.valueChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.checkForm()) {
				this.notfound = false;
			}
		});

		this.form?.get("gtinMode")?.valueChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(mode => {
			this.gtinMode = mode;
			if (this.gtinMode) {
				this.cleanStateTracker();
				this.resetData();
				this.setGtinValidation();
			}
			if (!this.gtinMode) {
				this.form?.get("gtin")?.clearValidators();
				this.form?.get("name")?.clearValidators();
				this.form?.get("gtin")?.updateValueAndValidity({ emitEvent: true });
				this.form?.get("name")?.updateValueAndValidity({ emitEvent: true });
			}
			this.setDefaultFormValue(this.gtinMode);
		});
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.selfFetchOption && simpleChanges.selfFetchOption.currentValue) {
			this.selfFetchOption = { ...this.defaultOption, ...this.selfFetchOption };
		}
	}

	public clearForm(isSwitchMode = false): void {
		if (isSwitchMode) {
			this.setDefaultFormValue(isSwitchMode);
			return;
		}
		this.setDefaultFormValue();
		this.input$$.next();
	}

	public search(): void {
		const formValues = this.form?.getRawValue() as TradeItemDto;
		delete formValues.gtinMode;
		if (this.form?.invalid) {
			ValidatorsUtil.triggerValidation(this.form);
			this.userErrorsService.displayErrors(this.form, undefined, undefined, true);
			
			return;
		}
		for (let k in formValues) {
			if (k === "name" && (formValues as any)[k]?.trim()) {
				const name = ((formValues as any)[k] as string)?.trim();
				formValues[k] = this.gtinMode ? name : name.toLocaleUpperCase();
			}
			if (k === "gtin" && (formValues as any)[k]?.trim() && !this.gtinMode) {
				formValues[k] = (formValues as any)[k]?.trim().replace(/^[0]*/, "");
			}
		}

		if (!Object.values(formValues).filter(el => !["", null, undefined].includes(el?.trim())).length) {
			return;
		}
		
		if (!!formValues.name && formValues.name?.trim()?.length < 4 && !!!formValues.gtin) {
			return;
		}
		if (this.gtinMode) {
			this.appFormValue.emit(formValues);
			return;
		}
		this.isLoading = true;
		const newState = { ...this.selfFetchOption.getInitialState(), ...formValues };

		this.input$$.next(newState);
	}

	public checkForm(): boolean {
		const formValues = this.form?.getRawValue();
		delete formValues.gtinMode;
		const dataValues = Object.values(formValues).filter(fl => !!fl);
		return !!dataValues.length;
	}

	public ngAfterViewInit(): void {
		this.setData(this.data);
		this.unfold();
		this.changeDetectorRef.markForCheck();
	}

	public select(item: TradeItemDto): void {
		this.collapse();
		this.appFormValue.emit(item);
		this.changeDetectorRef.markForCheck();
	}

	public onInput(search?: FilterTradeItemDto): void {
		this.resetData();
		this.cleanStateTracker();
		const newState = { ...this.selfFetchOption.getInitialState(), ...search };
		this.state$$.next(newState);
		this.stateTracker$$ = this.trackState$$();
	}

	public onScrolled(): void {
		this.state$$.next(this.selfFetchOption.nextChunk(this.state$$.value));
	}

	public collapse(): void {
		this.cleanStateTracker();
		this.resetData();
	}

	public unfold(): void {
		this.state$$.next(this.selfFetchOption.getInitialState());
		this.stateTracker$$ = this.trackState$$();
	}

	public ngOnDestroy(): void {
		this.cleanStateTracker();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public trackState$$(): Subject<void> {
		const unsubscribe$$ = new Subject<void>();
		this.state$$.pipe(
			filter(() => this.checkForm()),
			tap(() => this.overlayService.openLoadingPopup(true, "REQUEST_PROCESSING")),
			takeUntil(this.unsubscribe$$),
			takeUntil(unsubscribe$$)
		).subscribe(state => {
			if (!this.selfFetchOption.getData$)
				throw Error("No getData$ using for enrich date");

			this.selfFetchOption.getData$(state).pipe(
				take(1),
				map(data => this.selfFetchOption.mapData ? this.selfFetchOption.mapData(data) : data as any),
				takeUntil(this.unsubscribe$$),
				takeUntil(unsubscribe$$)
			).subscribe(data => {
				if (Array.isArray(data) && !data.length) {
					unsubscribe$$.next();
					unsubscribe$$.complete();
					this.isLoading = false;
				}
				this.appendData(data);
				this.overlayService.openLoadingPopup(false);
				this.isLoading = false;
				this.notfound = this.checkForm() && !!!this.data.length;
				this.changeDetectorRef.detectChanges();
			});
		});
		return unsubscribe$$;
	}

	private cleanStateTracker(): void {
		if (this.stateTracker$$) {
			this.stateTracker$$.next();
			this.stateTracker$$.complete();
			delete this.stateTracker$$;
		}
	}

	private resetData(): void {
		this.data = [];
	}

	private appendData(newData: TradeItemDto[]): void {
		this.data = [...this.data, ...newData];
	}

	private setData(newData: TradeItemDto[]): void {
		this.data = newData;
	}

	private setGtinValidation(): void {
		this.form?.get("gtin")?.setValidators([Validators.required, Validators.maxLength(14), ValidatorsUtil.checkSumGTIN(true), ValidatorsUtil.checkNumGTIN("29")]);
		this.form?.get("name")?.setValidators([Validators.required, Validators.maxLength(512)]);
		this.form?.get("gtin")?.updateValueAndValidity({ emitEvent: true });
		this.form?.get("name")?.updateValueAndValidity({ emitEvent: true });
	}

	private setDefaultFormValue(isMode = false): void {
		this.form?.patchValue({
			gtin: null,
			name: null,
			tnvedCode: null,
			gtinMode: isMode
		}, { emitEvent: false });
		this.form?.get("gtin")?.updateValueAndValidity({ emitEvent: true });
		this.form?.get("name")?.updateValueAndValidity({ emitEvent: true });
	}

	private initForm(): FormGroup {
		const form = this.fB.group({
			gtin: [null],
			name: [null],
			tnvedCode: [null],
			gtinMode: [this.gtinMode]
		});
		return form;
	}
}
