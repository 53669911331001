import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { ChangeUserPassword } from "@helper/abstraction/sections";
import { Observable } from "rxjs";
import { UserProfileDto, UserProfile, UserActivateParams, UserRecoverParams, GetUserFormCacheDto, UserFormCacheDto } from "@helper/abstraction/user";
import { map } from "rxjs/operators";
import { StoragesParams, StoragesDto } from "@helper/abstraction/storages";
import { Controller } from "./controller";
import {
	ManagementList,
	ManagementListParams,
	ManagementUserDto
} from "@helper/abstraction/management";
import { NotifyFlagsDto } from "@helper/abstraction/notifications";

export class User extends Controller {
	public get apiUrl(): string { return `${this.config.api.user}`; }
	public get apiGovUrl(): string { return `${this.config.api.gov}`; }

	public changePassword = {
		put$: (data: ChangeUserPassword): Observable<void> => {
			const url = `${this.apiUrl}/changePassword`;
			return this.http.put<void>(url, data, { withCredentials: true });
		}
	};

	public find = {
		get$: (id: number): Observable<ManagementUserDto> => {
			const url = `${this.apiUrl}/${id}`;
			return this.http.get<ManagementUserDto>(url, { withCredentials: true });
		}
	};

	public create = {
		post$: (user: ManagementUserDto): Observable<void> => {
			const url = `${this.apiUrl}`;
			return this.http.post<void>(url, user, { withCredentials: true });
		}
	};

	public edit = {
		put$: (user: ManagementUserDto): Observable<void> => {
			const url = `${this.apiUrl}`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public block = {
		put$: (user: number[]): Observable<void> => {
			const url = `${this.apiUrl}/block`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public unblock = {
		put$: (user: number[]): Observable<void> => {
			const url = `${this.apiUrl}/unblock`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public list = {
		post$: (params: ManagementListParams): Observable<ManagementList> => {
			const url = `${this.apiUrl}/list`;
			return this.http.post<ManagementList>(url, params, { withCredentials: true });
		}
	};

	public restore = {
		put$: (user: number[]): Observable<void> => {
			const url = `${this.apiUrl}/restore`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public delete = {
		delete$: (deleteIds: number[]): Observable<void> => {
			const url = `${this.apiUrl}/delete`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: deleteIds,
				withCredentials: true
			};
			return this.http.delete<void>(url, options);
		}
	};

	public checkApprovedCode = {
		post$: (code: string, userId: number): Observable<void> => {
			const url = `${this.apiUrl}/checkApprovedCode`;
			return this.http.post<void>(url, { code: code, userId: userId }, { withCredentials: true });
		}
	};

	public restorePassword = {
		post$: (email: string, unp: string): Observable<void> => {
			const url = `${this.apiUrl}/recoverPassword`;
			return this.http.post<void>(url, { userEmail: email, unp }, { withCredentials: true });
		}
	};

	public recoverPassword = {
		post$: (email: string): Observable<void> => {
			const url = `${this.apiGovUrl}/recoverPassword`;
			return this.http.post<void>(url, { userEmail: email }, { withCredentials: true });
		}
	};

	public getCurrentUserNotifyData = {
		get$: (): Observable<NotifyFlagsDto> => {
			const url = `${this.apiUrl}/getCurrentUserNotifyData`;
			return this.http.get<NotifyFlagsDto>(url, { withCredentials: true });
		}
	};

	public saveCurrentUserNotifyData = {
		put$: (notifyFlags: NotifyFlagsDto): Observable<void> => {
			const url = `${this.apiUrl}/saveCurrentUserNotifyData`;
			return this.http.put<void>(url, notifyFlags, { withCredentials: true });
		}
	};

	public profile = {
		get$: (): Observable<UserProfile> => {
			const url = `${this.apiUrl}/profile`;
			return this.http.get<UserProfileDto>(url, { withCredentials: true }).pipe(
				map(userProfile => {
					const profileData = {
						login: userProfile.login,
						lastName: userProfile.lastName,
						firstName: userProfile.firstName,
						middleName: userProfile.middleName,
						checkUnp: userProfile.checkUnp,
						mnsInfo: userProfile.nsiMnsDto,
						gtkInfo: userProfile.nsiGtkDto,
						jobPost: userProfile.jobPost,
						port: userProfile.edsProxyPort,
						edsProxyUrl: userProfile.edsProxyUrl,
						email: userProfile.email,
						phoneNumber: userProfile.phoneNumber,
						updateProfile: new Date(userProfile.updateProfile),
						lastAuthorization: new Date(userProfile.lastAuthorization),
						storage: userProfile.storage,
						storageSet: userProfile.storageSet,
						providerCode: userProfile.providerCode,
						certificateName: userProfile.certificate?.subjectName,
						certificateSerialNumber: userProfile.certificate?.serialNumber,
						mobileEds: userProfile.mobileEds ? {
							phoneNumber: userProfile.mobileEds.phoneNumber
						} : undefined,
						uiData: userProfile.uiData,
					};
					return profileData;
				})
			);
		},

		save: {
			post$: (data: UserProfile): Observable<void> => {
				const url = `${this.config.api.root}/user/profile/save`;
				return this.http.post<void>(url, data, { withCredentials: true });
			}
		}
	};

	public uiData = {
		get$: (): Observable<string> => {
			const url = `${this.apiUrl}/uiData`;
			return this.http.get<string>(url, { withCredentials: true }).pipe(
				map(data => data)
			);
		},

		save: {
			post$: (data: string): Observable<void> => {
				const url = `${this.apiUrl}/uiData`;
				return this.http.post<void>(url, data, { withCredentials: true });
			}
		}
	};

	public statuses = {
		list: {
			get$: (): Observable<any> => {
				const url = `${this.apiUrl}/statuses/list`;
				return this.http.get<any>(url, { withCredentials: true });
			}
		}
	};

	public storages = {
		list: {
			get$: (sp: StoragesParams): Observable<StoragesDto> => {
				const url = `${this.config.api.root}/user/storages/list`;
				let params = new HttpParams();
				params = params.set("page", sp.page.toString());
				params = params.set("size", sp.size.toString());
				if (sp.searchText)
					params = params.set("storageName", sp.searchText);
				return this.http.get<StoragesDto>(url, { params, withCredentials: true });
			}
		}
	};

	public activate = {
		post$: (body: UserActivateParams): Observable<any> => {
			const url = `${this.apiUrl}/activate`;
			return this.http.post<any>(url, body);
		}
	};

	public appInfo = {
		get$: (): Observable<{ clearCacheVersion: string; version: string }> => {
			const url = `${this.apiUrl}/appInfo`;
			return this.http.get<{ clearCacheVersion: string; version: string }>(url, { withCredentials: true });
		}
	};

	public saveRecoveredPassword = {
		post$: (body: UserRecoverParams): Observable<any> => {
			const url = `${this.apiUrl}/saveRecoveredPassword`;
			return this.http.post<any>(url, body);
		}
	};

	public userFormCache = {
		post$: (filter: GetUserFormCacheDto): Observable<UserFormCacheDto[]> => {
			const url = `${this.apiUrl}/form-cache`;
			const { page, size, ...filterDto } = filter;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			params = params.set("size", size.toString());
			return this.http.post<UserFormCacheDto[]>(url, filterDto, { withCredentials: true });
		}
	};

	public saveUserFormCache = {
		post$: (filter: UserFormCacheDto[]): Observable<void> => {
			const url = `${this.apiUrl}/form-cache/save`;
			return this.http.post<void>(url, filter, { withCredentials: true });
		}
	};

	public deleteUserFormCache = {
		post$: (item: UserFormCacheDto): Observable<void> => {
			const url = `${this.apiUrl}/form-cache/delete`;
			return this.http.post<void>(url, item, { withCredentials: true });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) {
		super();
	}
}
