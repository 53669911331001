import { ValidationErrors, ValidatorFn, AbstractControl, FormGroup, FormArray, Validators, FormControl } from "@angular/forms";
import { EventEmitter } from "@angular/core";
import { TextUtil } from "./text-util";

export class ValidatorsUtil {
	public static numberRegExp = /[0-9]/;
	public static lowercaseLatinCharacterRegExp = /[a-z]/;
	public static uppercaseLatinCharacterRegExp = /[A-Z]/;
	public static nonASCIICharacterRegExp = /[^\u0000-\u007F]+/;
	public static scSpecialCharacterRexExp = /[!@#$%^&*()]/;
	public static noSpaceCharacter = /[\s]/;
	public static noSpecialCharacter = /[=+_-`~'"<>{}?/|:/;[.,]/;
	private static textUtil = TextUtil;

	public static doNotMatch(firstField: string, secondField: string): ValidatorFn {
		return (form: AbstractControl): ValidationErrors | null => {
			const firstControl = form.get(firstField);
			const secondControl = form.get(secondField);
			if (firstControl && secondControl && firstControl.value !== secondControl.value)
				return { doNotMatch: true };
			else
				return null;
		};
	}
	public static maxOrdersMarksValue(maxValue: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;
			return +control.value > maxValue ? { maxOrdersMarksValue: true } : null;
		};
	}

	public static checkPeriod(firstField: string, secondField: string): ValidatorFn {
		return (form: AbstractControl): ValidationErrors | null => {
			const firstControl = form.get(firstField);
			const secondControl = form.get(secondField);
			if (firstControl?.value && secondControl?.value && firstControl.value > secondControl.value)
				return { checkPeriod: true };
			else
				return null;
		};
	}

	public static nonRepeatingCharacters(characters: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;

			const newStr = control.value.replace(/(.)(?=.*?\1)/g, "");
			if (newStr.length >= characters)
				return null;
			else
				return { repeating: true };
		};
	}

	public static checkLengthGLN(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;

			const s = control.value as string;
			if (s.length !== 13)
				return { wrongLengthGLN: true };
			return null;
		};
	}

	public static checkLength(count: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;
			const s = control.value as string;
			if (s.length !== count)
				return { wrongLength: true, length: count };
			return null;
		};
	}

	public static checkSumGLN(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;

			const s = control.value as string;
			if (s == null || !Number.isInteger(+s) || s.length < 2) {
				return { notGLN: true };
			}
			const calculated = ValidatorsUtil.checksum(s.slice(0, s.length - 1));
			const actual = s.charAt(s.length - 1);

			return actual == calculated ? null : { notGLN: true };
		};
	}

	public static checkEdsProxyPort(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;

			const s = +control.value;
			return s >= 65535 ? { notEdsProxyPort: true } : s < 1 ? { notPositiveEdsProxyPort: true } : null;
		};
	}

	public static checkSumGTIN(isCheckDigit = false): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			if (!(control.value.length >= 8 && control.value.length <= 14)) {
				return { notGTIN: true };
			}

			const checkDigit: string = (control.value as string)[control.value.length - 1];
			const withoutCheckDigit: string = (control.value as string).substring(0, (control.value as string).length - 1);
			const isCorrectGTIN: boolean = checkDigit === (!isCheckDigit
				? this.calculateCheckDigit(withoutCheckDigit)
				: this.calculateCheckDigitGTIN(withoutCheckDigit, (control.value as string).length));
			return isCorrectGTIN ? null : { notGTIN: true };
		};
	}

	public static checkNumGTIN(value: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value?.length || control.value[0] === null) {
				return null;
			}
			
			const controlValue = control.value?.length && control.value?.substring(0, 2) === value;
			return controlValue ? null : { notValidGTIN: true };
		};
	}

	public static checkEmptyAroundDot(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			if (control.value?.indexOf(".") !== -1 && control.value.split(".")[0] === "" || control.value.split(".")[0] !== "" && control.value.split(".")[1] === "") {
				return { emptyAroundDot: true };
			}
			return null;
		};
	}

	public static checkNumber(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;

			const s = control.value as string;
			if (s == null || Number.isNaN(+s) || +s === 0) {
				return { notNumber: true };
			}
			return null;
		};
	}

	public static onlyLatinOrSpecialCharactersAllowed(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;

			return this.nonASCIICharacterRegExp.test(control.value) || this.noSpaceCharacter.test(control.value) || this.noSpecialCharacter.test(control.value) ? { onlyLatinOrSpecialCharactersAllowed: true } : null;
		};
	}

	public static noOneSpecialCharacters(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;
			return this.scSpecialCharacterRexExp.test(control.value) ? null : { noOneSpecialCharacters: true };
		};
	}

	public static noOneNumberCharacters(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;
			return this.numberRegExp.test(control.value) ? null : { noOneNumberCharacters: true };
		};
	}

	public static noOneLowercaseLatinCharacters(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;
			return this.lowercaseLatinCharacterRegExp.test(control.value) ? null : { noOneLowercaseLatinCharacters: true };
		};
	}

	public static noOneUppercaseLatinCharacters(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value)
				return null;
			return this.uppercaseLatinCharacterRegExp.test(control.value) ? null : { noOneUppercaseLatinCharacters: true };
		};
	}

	public static triggerValidation(control: AbstractControl): void {
		if (control instanceof FormGroup) {
			const group = (control as FormGroup);

			for (const field in group.controls) {
				const c = group.controls[field];

				this.triggerValidation(c);
			}
		}
		else if (control instanceof FormArray) {
			const group = (control as FormArray);

			for (const field in group.controls) {
				const c = group.controls[field];

				this.triggerValidation(c);
			}
		}

		control.markAsTouched({ onlySelf: true });
		(control.statusChanges as EventEmitter<any>).emit();
	}

	public static requiredWithConjugation(conf: { kind: "M" | "F" }): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control)
				return null;
			if (Validators.required(control)) {
				return conf.kind === "M" ? { requiredM: true } : { requiredF: true };
			} else
				return null;
		};
	}

	// check if value in provide controls less than current control (only one level)
	public static lessThan(parent: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value)
				return null;
			return (control.value < control.parent?.get(parent)?.value) ? { lessThan: true } : null;
		};
	}

	public static moreThanYear(parent: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if ((!control || !control.value) || !control.parent?.get(parent)?.value) {
				return null;
			}
			const startDate = new Date(control.parent?.get(parent)?.value);
			const endDate = new Date(control.value);
			if (endDate.getFullYear() > startDate.getFullYear()) {
				if ((endDate.getFullYear() - startDate.getFullYear() > 1) || ((endDate.getFullYear() - startDate.getFullYear() === 1) && endDate.getMonth() > startDate.getMonth())) {
					return { moreThanYear: true };
				}
				if (((endDate.getFullYear() - startDate.getFullYear() === 1) && endDate.getMonth() === startDate.getMonth()) && endDate.getDate() > startDate.getDate()) {
					return { moreThanYear: true };
				}
			}
			return null;
		};
	}

	public static emailCustomValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			return this.isValidFields(control.value) && this.isValidLatinFields(control.value) ? null : { emailError: true };
		};
	}

	public static isSmallerOfYear(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			return this.isValidDate(control?.value) ? null : { isSmallerOfYear: true };
		};
	}

	public static isMoreOfToday(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			return this.isDateMoreOfToday(control?.value) ? null : { isMoreOfToday: true };
		};
	}

	public static isSmallerOfToday(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			return this.isDateLessThanToday(control?.value) ? { isSmallerOfToday: true } : null;
		};
	}

	public static BigSymbolAndNumbersFormat(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			return !this.lowercaseLatinCharacterRegExp.test(control?.value) && !this.scSpecialCharacterRexExp.test(control?.value)
				? null
				: { isNotBigSymbolAndNumbersFormat: true };
		};
	}

	public static StructureError(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			return this.isStructureError(control?.value)
				? null
				: { StructureError: true };
		};
	}

	public static AccountNumberStructureError(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			return this.isAccountNumberStructureError(control?.value)
				? null
				: { StructureError: true };
		};
	}

	public static requiredTrueCheckBox(): ValidationErrors {
		return function validate(control: FormControl): { requiredTrue: true } | null {
			if (control.value === true)
				return null;
			return { requiredTrue: true };
		};
	}

	public static isCheckNumberInterval = (firstNumber: number, secondNumber: number): ValidatorFn => {
		return (ctrl: AbstractControl): any => {
			const value = ctrl.value;
			if (!value) { return null; }
			const isInterval = value < firstNumber || value > secondNumber;
			const checkNumberIntervalError = { isCheckNumberIntervalError: { error: true, firstNumber, secondNumber } };
			return isInterval ? checkNumberIntervalError : null;
		};
	};

	public static isFormAdditionalField = (codes: string[]): ValidatorFn => {
		return (ctrl: AbstractControl): any => {
			const value = ctrl.value;
			if (!value) { return null; }
			const isCodeMatched = codes.includes(value);
			const formAdditionalFieldError = { formAdditionalFieldError: { error: true, value } };
			return isCodeMatched ? formAdditionalFieldError : null;
		};
	};

	public static requiredByPosition = (position: number): ValidatorFn => {
		return (ctrl: AbstractControl): any => {
			const value = ctrl.value;
			const requiredByPositionError = { requiredByPositionError: { error: true, position } };
			return !value || [null, undefined, ""].includes(String(value)?.trim()) ? requiredByPositionError : null;
		};
	};

	public static validateDeliveryNoteDate(isDateLimit = false, dayLimit?: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const today = new Date();
			const min = new Date(1900, 0, 1);
			const max = dayLimit && !isDateLimit
				? new Date(today.getFullYear(), today.getMonth(), today.getDate() + dayLimit)
				: new Date(today.getFullYear() + 10, today.getMonth(), today.getDate());
			const controlDate = control.value > max
				? this.textUtil.handleFormatDate(max)
				: this.textUtil.handleFormatDate(min);
			const controlErrorStatus =
				control.value && (this.textUtil.handleFormatDate(control.value, true) > this.textUtil.handleFormatDate(max, true)
					|| this.textUtil.handleFormatDate(control.value, true) < this.textUtil.handleFormatDate(min, true))
                		? { 'invalidDeliveryNoteDate': { error: true, controlDate, isMax: control.value > max } }
                		: null;
            return controlErrorStatus;
		}
	}

	public static minValue(position?: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control || !control.value) {
				return null;
			}
			const minValueError = { minValueError: { error: true, position } };
			return Number(control.value) > 0 ? null : minValueError;
		};
	}

	public static emptyFile(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return !control.value ? { emptyFile: true } : null;
		};
	}

	private static isAccountNumberStructureError(value: string): boolean {
		const arr = value.split("");
		let counter = 0;
		for (let i = 0; i < 4; i++) {
			if (i < 2 && this.numberRegExp.test(arr[i])) {
				counter++;
			}
			if (i >= 2 && !this.numberRegExp.test(arr[i])) {
				counter++;
			}
		}
		return counter <= 0;
	}

	private static isStructureError(value: string): boolean {
		const arr = value.split("");
		let counter = 0;
		for (let i = 0; i < value.length; i++) {
			if (i < 6 && this.numberRegExp.test(arr[i])) {
				counter++;
			}
		}
		return counter <= 0;
	}

	private static isDateMoreOfToday(value: Date): boolean {
		const date = new Date();

		if (value.getFullYear() === date.getFullYear()) {
			if (value.getMonth() === date.getMonth()) {
				if (value.getDate() - date.getDate() >= 0) {
					return false;
				}
			}
			else if (value.getMonth() > date.getMonth()) {
				return false;
			}
		}
		else if (value.getFullYear() > date.getFullYear()) {
			return false;
		}
		return true;
	}

	private static isDateLessThanToday(value: Date): boolean {
		const date = new Date();

		if (value.getFullYear() === date.getFullYear()) {
			if (value.getMonth() === date.getMonth()) {
				if (value.getDate() - date.getDate() >= 0) {
					return false;
				}
			}
			else if (value.getMonth() > date.getMonth()) {
				return false;
			}
		}
		else if (value.getFullYear() > date.getFullYear()) {
			return false;
		}
		return true;
	}

	private static isValidDate(value: Date): boolean {
		const date = new Date();

		if (value.getFullYear() - date.getFullYear() < 0 && value.getFullYear() - date.getFullYear() > -2) {
			if (value.getMonth() === date.getMonth()) {
				if (value.getDate() - (date.getDate() - 1) < 0) {
					return false;
				}
			}
			else if (value.getMonth() < date.getMonth()) {
				return false;
			}
		}
		else if (value.getFullYear() - date.getFullYear() <= -2) {
			return false;
		}
		return true;
	}

	private static calculateCheckDigit(str: string): string {
		let sum = 0;
		let mult = 1;
		for (const i of str) {
			sum += mult * +i;
			mult = mult === 3 ? 1 : 3;
		}
		const controlNumber = sum % 10;
		return controlNumber !== 0 ? `${10 - controlNumber}` : "0";
	}

	private static calculateCheckDigitGTIN(str: string, gtinLength: number): string {
		const itemDigits: number[] = [];
		const isEvenLength = !!(gtinLength % 2 === 0);

		str.split("").forEach((item: string, i: number) => {
			const idx: number = !isEvenLength ? i + 1 : i;
			itemDigits.push(Number(item) * (idx % 2 === 0 ? 3 : 1));
		});
		const sumDigit = itemDigits.reduce((pV, cV) => pV + cV);
		const checkDigit = Number(sumDigit % 10 === 0 ? sumDigit : Math.ceil(sumDigit / 10) + "0") - sumDigit;

		return checkDigit.toString();
	}

	private static checksum(s: string): string {
		let sum = 0;
		for (let i = 0, position = s.length; i < s.length; i++, position--) {
			const n = +s.charAt(i);
			sum += n + (n + n) * (position & 1);
		}
		return `${(10 - (sum % 10)) % 10}`;
	}

	private static isValidLatinFields(value: string): boolean {
		if (value.indexOf("@") === -1) {
			return false;
		}
		if (value.indexOf(".") === -1) {
			return false;
		}
		const emailArr = value?.split("@");
		if (emailArr.length > 2) {
			return false;
		}
		const beforeAt = emailArr && emailArr[0];
		let beforeDote = "";
		let afterDote = "";
		if (emailArr[1]?.split(".").length > 2) {
			beforeDote = this.setMultiplyBeforeDote(emailArr[1]?.split("."));
			afterDote = emailArr[1]?.split(".")[emailArr[1]?.split(".").length - 1];
		}
		else {
			beforeDote = emailArr && emailArr[1]?.split(".")[0];
			afterDote = emailArr && emailArr[1]?.split(".")[1];
		}

		const re = /[а-яА-Я]|[\s]/;

		if (re.test(beforeAt) || re.test(beforeDote) || re.test(afterDote)) {
			return false;
		}
		if (!beforeAt || !beforeDote || !afterDote) {
			return false;
		}
		if (beforeAt && (beforeAt?.length < 1 || beforeAt?.length > 64)) {
			return false;
		}

		if (beforeDote && (beforeDote?.length < 1 || beforeDote?.length > 63)) {
			return false;
		}

		if (afterDote && (afterDote?.length < 1 || afterDote?.length > 6)) {
			return false;
		}

		return true;
	}

	private static isValidFields(value: string): boolean {
		if (value.indexOf("@") === -1) {
			return false;
		}
		if (value.indexOf(".") === -1) {
			return false;
		}
		const emailArr = value?.split("@");
		if (emailArr.length > 2) {
			return false;
		}
		let beforeDote = "";
		let afterDote = "";
		const beforeDoggy = emailArr && emailArr[0];
		if (emailArr[1]?.split(".").length > 2) {
			beforeDote = this.setMultiplyBeforeDote(emailArr[1]?.split("."));
			afterDote = emailArr[1]?.split(".")[emailArr[1]?.split(".").length - 1];
		}
		else {
			beforeDote = emailArr && emailArr[1]?.split(".")[0];
			afterDote = emailArr && emailArr[1]?.split(".")[1];
		}
		if (this.doublePointInPart(beforeDoggy) || this.doublePointInPart(beforeDote)
			|| this.badFirstOrLastSymbol(beforeDoggy, ".") || this.badFirstOrLastSymbol(beforeDote, ".")) {
			return false;
		}
		if (this.withSpecSymbol(beforeDoggy) || this.withSpecSymbol(beforeDote, "_") || this.withSpecSymbol(afterDote, "-._")) {
			return false;
		}
		return true;
	}

	private static withSpecSymbol(val: string, optionalString?: string): boolean {
		let iChars = "~`!#$%^&*+=[]\\';,/{}|\":<>?";
		if (optionalString) {
			iChars += optionalString;
		}
		for (let i = 0; i < val.length; i++) {
			if (iChars.indexOf(val.charAt(i)) != -1) {
				return true;
			}
		}
		return false;
	}

	private static doublePointInPart(val: string): boolean {
		for (let i = 1; i < val.length; i++) {
			if (val.charAt(i - 1) === "." && val.charAt(i) === ".") {
				return true;
			}
		}
		return false;
	}

	private static setMultiplyBeforeDote(array: string[]): string {
		let beforeDote = "";
		for (let i = 0; i < array.length - 1; i++) {
			beforeDote += array[i];
			if (i !== array.length - 2) beforeDote += ".";
		}
		return beforeDote;
	}

	private static badFirstOrLastSymbol(val: string, symbol: string): boolean {
		for (let i = 0; i < symbol.length; i++) {
			if (val[0] === symbol.charAt(i) || val[val.length - 1] === symbol.charAt(i)) {
				return true;
			}
		}
		return false;
	}
}
