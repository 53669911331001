import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, Type, ViewChild } from "@angular/core";
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BoxValueAccessor } from "@shared/box-value-accessor/BoxValueAccessor";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export interface FileImportInterface {
	fileName: string;
	fileValue: string[];
	size: number;
}

@Component({
	selector: "app-file-import",
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		multi: true,
		useExisting: forwardRef((): Type<FileImportComponent> => FileImportComponent)
	}],
	templateUrl: "./file-import.component.html",
	styleUrls: ["./file-import.component.scss", "./mass-import-styles.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileImportComponent extends BoxValueAccessor implements OnInit {
	//#region Input variables
	@Input() public formControlName?: string;
	@Input() public displayValidation = true;
	@Input() public userStatusesList = false;
	@Input() public isOutsideMessage = false;
	@Input() public formControl?: AbstractControl;
	@Input() public maxFilesSize = 10000;
	@Input() public maxValueCount?: number;
	@Input() public massImport = false;
	@Input() public error = false;
	@Input() public showSearch = true;
	@Input() public fileFormats: string[] = ["txt"];
	@Input() public errorsMessage: [string, string][] = [];

	@Output() public outsideMessage?: EventEmitter<string> = new EventEmitter<string>();
	//#endregion
	@ViewChild(OverlayComponent, { static: true }) public overlayComponent?: OverlayComponent;

	public file?: any;
	public files: FileImportInterface[] = [];
	public size = 0;

	//#region public variables
	public disabled: boolean | null = null;
	public presetValues: [any, string][] = [];
	public list: [any, string][] = [];
	public expand = false;
	public showError = false;
	public control?: AbstractControl | null;
	public unsubscribe$$ = new Subject<void>();
	public isPopupDisplay = false;
	//#endregion

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		public readonly controlContainer: ControlContainer,
	) {
		super();
		this.value = [];
	}

	public get selectedFilesSize(): number {
		return this.files.reduce((total, el) => total + el.size, 0);
	}


	public ngOnInit(): void {
		if (!this.displayValidation){
			return;
		}

		if (this.formControl){
			this.control = this.formControl;
		}

		if (this.controlContainer && this.formControlName && this.controlContainer.control){
			this.control = this.controlContainer.control.get(this.formControlName);
		}

		// Subscribe need to change control touched state
		this.control && this.control.statusChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(() => {
			if (!this.error){
				this.showError = this.control && this.control.touched && this.control.invalid || false;
			} else{
				this.showError = this.error;
			}

			this.changeDetectorRef.detectChanges();
		});
	}

	public addFile(event: Event): void {
		const target = event.target || event.srcElement;
		const filesAdded = (target as HTMLInputElement).files as FileList;
		this.checkFiles(filesAdded);
	}

	public fileDropped(filesDropped: FileList): void {
		this.checkFiles(filesDropped);
	}

	public deleteFile(file: any): void {
		this.files.splice(this.files.findIndex(el => JSON.stringify(el) === JSON.stringify(file)), 1);
		this.save();
	}

	public save(): void {
		this.value = [...this.files];
		this.changeAndTouch();
		if (this.isPopupDisplay) {
			this.openPopup(false);
		}
	}

	public openPopup(isOpen: boolean): void {
		this.isPopupDisplay = isOpen;
	}

	private checkFiles(filesAdded: FileList): void {
		const filesList: File[] = [];
		Object.keys(filesAdded).forEach((v, key) => {
			if (this.fileFormats.includes(filesAdded[key].name.split(".")[1])) {
				filesList.push(filesAdded[key]);
			}
		});
		if (!filesList.length) {
			const message = filesAdded.length > 1 ? "Выбранные файлы не соответствуют нужному типу" : "Выбранный файл не соответствует нужному типу";
			if (this.isOutsideMessage) {
				this.outsideMessage?.emit(message);
			} else {
				this.overlayComponent?.showNotification$(`${message} - *.${this.fileFormats[0]}`, "error", 3000000, undefined, true);
			}
			return;
		}

		if (Object.keys(filesAdded).length !== filesList.length) {
			if (this.isOutsideMessage) {
				this.outsideMessage?.emit(`Максимальный общий размер файлов - ${this.maxFilesSize} kB`);
			} else {
				this.overlayComponent?.showNotification$(`Максимальный общий размер файлов - ${this.maxFilesSize} kB`, "error");
			}
		}

		filesList.forEach(file => {
			this.setFile(file);
		});
	}

	private setFile(file: File): void {
		if (this.files.some(el => el.fileName === file.name)) {
			this.files.splice(this.files.findIndex(el => el.fileName === file.name), 1);
		}
		this.size = this.massImport? this.size + file.size / 1024 : file.size / 1024;

		if (this.size > this.maxFilesSize) {
			if (!this.massImport && !this.isOutsideMessage) {
				this.overlayComponent?.showNotification$(`Максимальный общий размер файлов - ${this.maxFilesSize} kB`, "error");
			}
			if (!this.massImport && this.isOutsideMessage) {
				this.outsideMessage?.emit(`Максимальный общий размер файлов - ${this.maxFilesSize} kB`);
			}
			return;
		}
		const reader = new FileReader();
		reader.readAsText(file, "Windows-1251");

		reader.onload = (): void => {
			const result = reader.result;
			const fileValue = (result as string).split(/\r\n|\n/).filter(el => el !== "" && el.trim() !== "");
			if (!fileValue.length) {
				if (this.isOutsideMessage) {
					this.outsideMessage?.emit("В файле импорта коды не обнаружены.");
				} else {
					this.overlayComponent?.showNotification$("В файле импорта коды не обнаружены.", "error");
				}
				this.changeDetectorRef.detectChanges();
				return;
			} 
			
			if (this.maxValueCount && fileValue.length > this.maxValueCount) {
				if (this.isOutsideMessage) {
					this.outsideMessage?.emit(`Загружено информации более чем о ${this.maxValueCount} записей. ` +  
						"Процесс сохранения может занять длительное время. Разбейте файл на несколько частей и повторите загрузку.");
				} else {
					this.overlayComponent?.showNotification$(`Загружено информации более чем о ${this.maxValueCount} записей. ` +  
						"Процесс сохранения может занять длительное время. Разбейте файл на несколько частей и повторите загрузку.", "error");
				}
				this.changeDetectorRef.detectChanges();
				return;
			}

			this.files.push({ fileName: file.name, size: file.size / 1024 || 0, fileValue });
			if (!this.massImport) {
				this.save();
			}
			this.changeDetectorRef.detectChanges();
		};

		reader.onerror = (error): void => {
			throw error.type;
		};
	}

	private changeAndTouch(): void {
		if (this.onChange) {
			this.onChange(this.value);
		}
		if (this.onTouched){
			this.onTouched();
		}
	}

}
